import React, { useEffect, useState } from "react";
import { useParams, Link, useRouteMatch, useLocation } from "react-router-dom";
import IconCard from "../../../components/UI/IconCard/IconCard";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import ErrorCard from "../../../components/UI/ErrorCard/ErrorCard";
import JobCard from "./partials/JobCard";
import {
  Row,
  Col,
  Card,
  Skeleton,
  Empty,
  Button,
  Typography,
  Tag,
  Divider,
} from "antd";
import { Fade } from "react-awesome-reveal";
import { useGetSingleJobQuery } from "../../../graphql/generated/graphql";
import moment from "moment";
import styled from "styled-components";
import { encrypt } from "../../../helpers/crypto";
import AssessmentTable from "./partials/AssessmentTable";
import YearlyCompensation from "./partials/YearlyCompensation";
import { JobDetailIcon } from "assets/icons/DetailedProfile";
const { Title } = Typography;

interface ViewJobProps {}

const ViewJob: React.FC<ViewJobProps> = () => {
  let { jobId } = useParams<any>();
  const [user, setUser] = useState<any>();
  const { url, path } = useRouteMatch();
  const location: any = useLocation();
  //handle view
  const [hasData, setHasData] = useState(true);

  const getData = async () => {
    const currentUser: any = await localStorage.getItem("user");
    setUser(JSON.parse(currentUser));
  };

  const { data, loading, error } = useGetSingleJobQuery({
    variables: {
      jobId: jobId,
    },
    fetchPolicy: "network-only",
  });

  const getInitials = (name: any) =>
    name &&
    name
      .replace(/[^a-zA-Z- ]/g, "")
      .match(/\b\w/g)
      .join("");

  useEffect(() => {
    getData();
    data && data?.getJobDetails === null && setHasData(false);
  }, [data]);
  return (
    <>
      <PageTitle
        title="Job information"
        btnText="Edit job"
        to={`/job-listings/edit/${jobId}`}
        back
        backURL="/job-listings"
        btnActive={true}
      />
      {!loading && !data ? (
        <ErrorCard />
      ) : (
        <Row justify="center" className="pb-4" gutter={24}>
          <Col span={17}>
            <Fade>
              <JobContainer className="directory-card-job">
                <Skeleton active paragraph={{ rows: 10 }} loading={loading}>
                  {data && data.getJobDetails !== null ? (
                    <JobCard job={data?.getJobDetails} />
                  ) : (
                    <div className="p-4 m-t-40 m-b-40">
                      <Empty description={<span>No Job found</span>}>
                        {/* <Link to={"/job-listings/new"}>
                          <Button
                            className="ant-btn-amber"
                            shape="round"
                            size="middle"
                          >
                            Create new job
                          </Button>
                        </Link> */}
                      </Empty>
                    </div>
                  )}
                </Skeleton>
              </JobContainer>
            </Fade>
          </Col>
          {hasData && (
            <Col span={7}>
              <Button
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  // width: "90%",
                  margin: "0px auto 10px auto",
                  paddingTop: "0px !important",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "normal",
                }}
                href={`${process.env.REACT_APP_WOB_URL}/job/${
                  data?.getJobDetails?._id
                }?media=${encrypt(
                  `Link_Pro_${getInitials(user?.fullName)}`
                )}&version=${Date.now()}`}
                target="_blank"
                className="ant-btn-amber"
                htmlType="submit"
                shape="round"
                // disabled={hasError}
                // loading={isLoading}
              >
                Landing page
              </Button>
              {data?.getJobDetails?.progressReport &&
                data?.getJobDetails?.progressReport !== "none" && (
                  <Button
                    block
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      // width: "90%",
                      margin: "0px auto 10px auto",
                      paddingTop: "0px !important",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                    }}
                    href={data?.getJobDetails?.progressReport}
                    target="_blank"
                    className="ant-btn-burgundy"
                    htmlType="submit"
                    shape="round"
                    // disabled={hasError}
                    // loading={isLoading}
                  >
                    Progress Report
                  </Button>
                )}
              <IconCard
                color="primary"
                icon="far fa-calendar-alt"
                label="Search project start"
                loading={loading}
              >
                {/* {company && company.createdAt} */}
                {data?.getJobDetails &&
                  moment(
                    parseInt(data?.getJobDetails?.createdAt || "0")
                    //"Sun Jul 05 2020 23:25:04 GMT+0500 (Pakistan Standard Time)"
                  ).format("DD/MM/YY")}
              </IconCard>
              {data?.getJobDetails && data?.getJobDetails?.candidates && (
                <IconCard
                  color="amber"
                  icon="dripicons-user"
                  label="List of candidates"
                  loading={loading}
                >
                  <Link to={`/candidate-listings/${data?.getJobDetails?._id}`}>
                    <Button
                      className="ant-btn-teal"
                      style={{
                        display: "flex",
                        padding: "2px 14px",
                        height: "22px",
                        width: "44px",
                        borderRadius: "2px",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "18px",
                        justifyContent: "center",
                      }}
                    >
                      {data?.getJobDetails &&
                        data?.getJobDetails?.candidates?.length}
                    </Button>
                  </Link>
                </IconCard>
              )}
              {/* <IconCard
                color="teal"
                icon="dripicons-pin"
                label="Location"
                loading={loading}
              >
                {data?.getJobDetails && data?.getJobDetails?.countries.join(',')}
                <RowWithTagsStyle>
                  {data?.getJobDetails &&
                    data?.getJobDetails?.countries &&
                    data?.getJobDetails.countries.map((country: any) => (
                      <Tag key={country._id} color="#2e4a79">
                        {country.name?.charAt(0).toUpperCase() +
                          country.name?.substr(1)}
                      </Tag>
                    ))}
                </RowWithTagsStyle>
              </IconCard> */}
              <Card className="mb-2 displayCards">
                <Skeleton active loading={loading} avatar>
                  <Row justify="center" gutter={[0, 16]}>
                    <Col span={24} className="align-self-center">
                      <Title
                        className="mt-0 text-primary"
                        style={{ fontSize: "18px" }}
                      >
                        <img
                          src={JobDetailIcon}
                          alt="job icon"
                          style={{ marginRight: "10px" }}
                        />
                        Job details
                      </Title>
                      <MyDivider></MyDivider>
                      <OverviewSection>
                        <Row>
                          <OverviewSubHeadingWithIcon>
                            <i className={"dripicons-location"}></i>
                            <p>Job location:</p>
                          </OverviewSubHeadingWithIcon>
                        </Row>
                        {/* <RowWithTagsStyle fontColor=" #313131;">
                          {data?.getJobDetails &&
                            data?.getJobDetails?.countries &&
                            data?.getJobDetails.countries.map(
                              (country: any) => (
                                <Tag key={country._id} color="#fff">
                                  {country.name?.charAt(0).toUpperCase() +
                                    country.name?.substr(1)}
                                </Tag>
                              )
                            )}
                        </RowWithTagsStyle> */}
                        {data?.getJobDetails &&
                          data?.getJobDetails?.countries &&
                          data?.getJobDetails.countries.map((country: any) => (
                            <RowWithTagsStyle fontColor=" #313131;">
                              {formatedLocation(country?.name)}
                            </RowWithTagsStyle>
                          ))}
                      </OverviewSection>
                      <OverviewSection>
                        <Row>
                          <OverviewSubHeadingWithIcon>
                            <i className={"dripicons-lightbulb"}></i>
                            <p>Required skills:</p>
                          </OverviewSubHeadingWithIcon>
                        </Row>
                        <RowWithTagsStyle>
                          {data?.getJobDetails &&
                            data?.getJobDetails.requiredSkills.map((skill) => (
                              <Tag key={skill?._id} color="#418394">
                                {skill?.title}
                              </Tag>
                            ))}
                        </RowWithTagsStyle>
                      </OverviewSection>
                      <OverviewSection>
                        <Row>
                          <OverviewSubHeadingWithIcon>
                            <i className={"dripicons-map"}></i>
                            <p>Job fields:</p>
                          </OverviewSubHeadingWithIcon>
                        </Row>
                        <RowWithTagsStyle>
                          {data?.getJobDetails &&
                            data?.getJobDetails.fields.map(
                              (field: any, index) => (
                                <Tag key={index} color="#2e4a79">
                                  {field.title}
                                </Tag>
                              )
                            )}
                        </RowWithTagsStyle>
                      </OverviewSection>
                      <OverviewSection>
                        <Row>
                          <OverviewSubHeadingWithIcon>
                            <i className={"dripicons-user"}></i>
                            <p>Lead recruiter:</p>
                          </OverviewSubHeadingWithIcon>
                        </Row>
                        <RowWithTagsStyle fontColor=" #313131;">
                          {data?.getJobDetails &&
                            data?.getJobDetails.leadRecruiter && (
                              <Tag
                                color="#dcdcdc"
                                style={{ backgroundColor: "#FAFAFA" }}
                              >
                                {data?.getJobDetails.leadRecruiter.email}
                              </Tag>
                            )}
                        </RowWithTagsStyle>
                      </OverviewSection>
                      <OverviewSection>
                        <Row>
                          <OverviewSubHeadingWithIcon>
                            <i className={"dripicons-user-group"}></i>
                            <p>Recruiting team:</p>
                          </OverviewSubHeadingWithIcon>
                        </Row>
                        <RowWithTagsStyle fontColor=" #313131;">
                          {data?.getJobDetails &&
                            data?.getJobDetails?.recruiters?.map(
                              (recruiter, index) => (
                                <Tag key={index} color="#dcdcdc;">
                                  {recruiter?.email}
                                </Tag>
                              )
                            )}
                        </RowWithTagsStyle>
                      </OverviewSection>
                    </Col>
                  </Row>
                </Skeleton>
              </Card>
              {/* <Card className="mb-3 icon-card">
                <Skeleton active loading={loading} avatar>
                  <Row justify="center" gutter={16}>
                    <Col span={24} className="align-self-center">
                      <Title
                        level={4}
                        className="mt-0 text-primary"
                        style={{ fontSize: "18px" }}
                      >
                        Contract details
                      </Title>
                      <MyDivider></MyDivider>

                      <OverviewSection>
                        <Row>
                          <OverviewSubHeadingWithIcon>
                            <i className={"dripicons-to-do"}></i>
                            <p>Contract type:</p>
                          </OverviewSubHeadingWithIcon>
                        </Row>
                        <RowWithTransparentBorderTagsStyle fontColor=" #313131;">
                          {data?.getJobDetails &&
                            data?.getJobDetails.contractType && (
                              <Tag color="white">
                                {data?.getJobDetails.contractType.name}
                              </Tag>
                            )}
                        </RowWithTransparentBorderTagsStyle>
                      </OverviewSection>
                      <OverviewSection>
                        <Row>
                          <OverviewSubHeadingWithIcon>
                            <i className={"dripicons-card"}></i>
                            <p>Salary package:</p>
                          </OverviewSubHeadingWithIcon>
                        </Row>
                        <RowWithTransparentBorderTagsStyle fontColor=" #313131;">
                          {data?.getJobDetails &&
                            data?.getJobDetails?.salaryPackage && (
                              <Tag color="white;">
                                {data?.getJobDetails?.salaryPackage}
                              </Tag>
                            )}
                        </RowWithTransparentBorderTagsStyle>
                      </OverviewSection>
                      {data?.getJobDetails &&
                        data?.getJobDetails?.targetCompanies.length > 0 && (
                          <OverviewSection>
                            <Row>
                              <OverviewSubHeadingWithIcon>
                                <i className={"dripicons-user-group"}></i>
                                <p> Target companies:</p>
                              </OverviewSubHeadingWithIcon>
                            </Row>
                            <RowWithTransparentBorderTagsStyle fontColor=" #313131;">
                              {data?.getJobDetails &&
                                data?.getJobDetails?.targetCompanies.map(
                                  (targetCompany) => (
                                    <Tag key={targetCompany._id} color="white;">
                                      {targetCompany.name}
                                    </Tag>
                                  )
                                )}
                            </RowWithTransparentBorderTagsStyle>
                          </OverviewSection>
                        )}
                    </Col>
                  </Row>
                </Skeleton>
              </Card> */}
              <YearlyCompensation
                nocomments={location?.state?.comments}
                candidate={data?.getJobDetails?.yearlyCompensation}
              />
              {data?.getJobDetails?.assessment &&
                (data?.getJobDetails?.assessment.experience.length > 0 ||
                  data?.getJobDetails?.assessment.traits.length > 0) && (
                  <AssessmentTable
                    assessmentData={data?.getJobDetails?.assessment}
                  />
                )}
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

const OverviewSubHeadingWithIcon = styled.div`
  display: flex;
  flex-direction: "row";
  color: #bc9623 !important;
  font-weight: 400;
  margin: 0px;
  padding: 0px;

  i {
    margin-right: 5px;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 5px;
  }
`;
const MyDivider = styled(Divider)`
  margin: 10px 0px;
`;

const RowWithTagsStyle: any = styled(Row)`
  span {
    border-radius: 15px;
    padding: 0.2vh 1vw;
    color: ${(props: any) => props.fontColor || "white"};
    margin: 3px;
    background: #fafafa;
    overflow: hidden;
  }
`;
const RowWithTransparentBorderTagsStyle: any = styled(RowWithTagsStyle)`
  span {
    border: 1px solid #2e4a79;
    margin: 3px;
  }
`;

const OverviewSection = styled.div`
  margin-bottom: 30px;
`;

const JobContainer: any = styled(Card)`
  border-radius: 16px !important;
  margin-bottom: 20;
  box-shadow: none;
`;

const formatedLocation = (cityAndCountry: any) => {
  if (cityAndCountry) {
    const [city, country] = cityAndCountry?.split(", ");
    const formattedCountry = country
      ? country.charAt(0).toUpperCase() + country.slice(1)
      : "";

    const fontWeight = !formattedCountry ? "500" : "0";
    return (
      <Typography
        style={{
          marginLeft: "8px",
          color: "#495057",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
        }}
      >
        <span style={{ padding: "0px", margin: "0px", fontWeight: fontWeight }}>
          {city}
          {formattedCountry && ","}{" "}
        </span>
        <span
          style={{
            padding: "0px",
            margin: "0px",
            fontWeight: "500",
            color: "#000000",
          }}
        >
          {formattedCountry}
        </span>
      </Typography>
    );
  } else {
    return <span></span>;
  }
};

export default ViewJob;
