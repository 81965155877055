import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
  Void: any;
};

export type AddInspiringCompanyInput = {
  name?: Maybe<Scalars['String']>;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  _id: Scalars['ID'];
  userId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  verified?: Maybe<Scalars['Boolean']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type AiUserProfile = {
  __typename?: 'AiUserProfile';
  _id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  yearlyCompensation?: Maybe<YearlyCompensation>;
  nextSteps?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Languages>>>;
  languageComments?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  personalInformation?: Maybe<Scalars['String']>;
  benchMark?: Maybe<Scalars['String']>;
  totalExperience?: Maybe<Scalars['Int']>;
  experiences?: Maybe<Array<Maybe<CandidateExperience>>>;
  resume?: Maybe<Scalars['String']>;
  education?: Maybe<Array<Maybe<CandidateEducation>>>;
  educationComment?: Maybe<Scalars['String']>;
  countries?: Maybe<Array<Maybe<Country>>>;
  targetLocations?: Maybe<Array<Maybe<TargetLocations>>>;
  isPublic?: Maybe<Scalars['Boolean']>;
  currentLocation?: Maybe<Location>;
  location?: Maybe<Array<Maybe<Location>>>;
  profilePicture?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  objectives?: Maybe<Array<Maybe<Objectivestype>>>;
  professionalInterests?: Maybe<Array<Maybe<ProfessionalIntereststype>>>;
  targetRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  desiredSalaryPackage?: Maybe<Scalars['String']>;
  currentSalaryPackage?: Maybe<Scalars['String']>;
  isOnlyNetworker?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  appliedJobs?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<TagsType>>>;
  contractIdType?: Maybe<Scalars['String']>;
  sectors?: Maybe<Array<Maybe<SectorsType>>>;
  company?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  skills?: Maybe<Array<Maybe<SkillsType>>>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  response?: Maybe<Response>;
  user?: Maybe<User>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  recruiterVisible?: Maybe<Scalars['Boolean']>;
  resumeName?: Maybe<Scalars['String']>;
  coverLetterName?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  targetSalary?: Maybe<TargetSalary>;
  inspiringCompanies?: Maybe<Array<Maybe<InspiringCompanyType>>>;
  registrationStepNo?: Maybe<Scalars['Int']>;
  ResumePreview?: Maybe<Scalars['String']>;
  attachmentPreview?: Maybe<Scalars['String']>;
  languagePreference?: Maybe<Scalars['String']>;
  userSkills?: Maybe<Array<Maybe<UserSkill>>>;
  linkedInProfileUrl?: Maybe<Scalars['String']>;
};

export type AllNotificationToRead = {
  __typename?: 'AllNotificationToRead';
  message?: Maybe<Scalars['String']>;
};

export enum AppType {
  Mobile = 'MOBILE',
  Soblonk = 'SOBLONK',
  Backoffice = 'BACKOFFICE',
  Wob = 'WOB'
}

export type AssessmentInputView = {
  title?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type AssessmentView = {
  __typename?: 'AssessmentView';
  title?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  experience?: Maybe<Array<Maybe<AssessmentInputStructure>>>;
  traits?: Maybe<Array<Maybe<AssessmentInputStructure>>>;
};

export type CandidateEducation = {
  __typename?: 'CandidateEducation';
  _id?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  degree?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Boolean']>;
  endYear?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  location?: Maybe<LocationTypeEducationAndExperience>;
};

export type CandidateExperience = {
  __typename?: 'CandidateExperience';
  _id?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
  endYear?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Boolean']>;
  sector?: Maybe<Scalars['String']>;
  jobField?: Maybe<Array<Maybe<Scalars['String']>>>;
  reasonForChange?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  location?: Maybe<LocationTypeEducationAndExperience>;
  logo?: Maybe<Scalars['String']>;
  commentData?: Maybe<ExperienceCommentData>;
  experienceDetails?: Maybe<Scalars['String']>;
};

export type CandidateExperienceInput = {
  position?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
  endYear?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Boolean']>;
  sector?: Maybe<Scalars['String']>;
  jobField?: Maybe<Array<Maybe<Scalars['String']>>>;
  reasonForChange?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  location?: Maybe<LocationExperienceInput>;
  logo?: Maybe<Scalars['String']>;
  experienceDetails?: Maybe<Scalars['String']>;
};

export type CandidateInfoInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  position: Scalars['String'];
  company: Scalars['String'];
  country: Scalars['String'];
  skills: Array<Scalars['String']>;
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
  educations?: Maybe<Array<Maybe<EducationInput>>>;
  currentLocation?: Maybe<LocationInput>;
  customSkills?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileVideo?: Maybe<Scalars['String']>;
  profilePicture: Scalars['String'];
  coverPhoto?: Maybe<Scalars['String']>;
  resume: Scalars['String'];
  coverLetter?: Maybe<Scalars['String']>;
  resumeName?: Maybe<Scalars['String']>;
  coverLetterName?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  linkedIn?: Maybe<Scalars['String']>;
};

export type CandidateList = {
  __typename?: 'CandidateList';
  _id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  ResumePreview?: Maybe<Scalars['String']>;
  attachmentPreview?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  userSource?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  userIsProfileCompleted?: Maybe<Scalars['Boolean']>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  count?: Maybe<Scalars['Int']>;
};

export type CandidateListing = {
  __typename?: 'CandidateListing';
  _id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  yearlyCompensation?: Maybe<YearlyCompensation>;
  nextSteps?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Languages>>>;
  languageComments?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  personalInformation?: Maybe<Scalars['String']>;
  benchMark?: Maybe<Scalars['String']>;
  totalExperience?: Maybe<Scalars['Int']>;
  experiences?: Maybe<Array<Maybe<CandidateExperience>>>;
  resume?: Maybe<Scalars['String']>;
  education?: Maybe<Array<Maybe<CandidateEducation>>>;
  educationComment?: Maybe<Scalars['String']>;
  countries?: Maybe<Array<Maybe<Country>>>;
  targetLocations?: Maybe<Array<Maybe<TargetLocations>>>;
  isPublic?: Maybe<Scalars['Boolean']>;
  currentLocation?: Maybe<Location>;
  location?: Maybe<Array<Maybe<Location>>>;
  profilePicture?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  objectives?: Maybe<Array<Maybe<Objectivestype>>>;
  professionalInterests?: Maybe<Array<Maybe<ProfessionalIntereststype>>>;
  targetRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  desiredSalaryPackage?: Maybe<Scalars['String']>;
  currentSalaryPackage?: Maybe<Scalars['String']>;
  isOnlyNetworker?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  appliedJobs?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<TagsType>>>;
  contractIdType?: Maybe<Scalars['String']>;
  sectors?: Maybe<Array<Maybe<SectorsType>>>;
  company?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  skills?: Maybe<Array<Maybe<SkillsType>>>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  response?: Maybe<Response>;
  user?: Maybe<User>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  recruiterVisible?: Maybe<Scalars['Boolean']>;
  resumeName?: Maybe<Scalars['String']>;
  coverLetterName?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  targetSalary?: Maybe<TargetSalary>;
  inspiringCompanies?: Maybe<Array<Maybe<InspiringCompanyType>>>;
  registrationStepNo?: Maybe<Scalars['Int']>;
  ResumePreview?: Maybe<Scalars['String']>;
  attachmentPreview?: Maybe<Scalars['String']>;
};

export type CandidateListingProfile = {
  __typename?: 'CandidateListingProfile';
  _id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  yearlyCompensation?: Maybe<YearlyCompensation>;
  nextSteps?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Languages>>>;
  languageComments?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  personalInformation?: Maybe<Scalars['String']>;
  benchMark?: Maybe<Scalars['String']>;
  totalExperience?: Maybe<Scalars['Int']>;
  experiences?: Maybe<Array<Maybe<CandidateExperience>>>;
  resume?: Maybe<Scalars['String']>;
  resumeName?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  coverLetterName?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  education?: Maybe<Array<Maybe<CandidateEducation>>>;
  educationComment?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  currentLocation?: Maybe<Location>;
  profilePicture?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  objectives?: Maybe<Array<Maybe<Objectivestype>>>;
  professionalInterests?: Maybe<Array<Maybe<ProfessionalIntereststype>>>;
  targetRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  desiredSalaryPackage?: Maybe<Scalars['String']>;
  currentSalaryPackage?: Maybe<Scalars['String']>;
  isOnlyNetworker?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  appliedJobs?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<TagsType>>>;
  contractIdType?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  skills?: Maybe<Array<Maybe<SkillsType>>>;
  title?: Maybe<Scalars['String']>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  response?: Maybe<Response>;
  user?: Maybe<User>;
  targetLocations?: Maybe<Array<Maybe<CandidateTargetLocation>>>;
  sectors?: Maybe<Array<Maybe<SectorsType>>>;
  targetSalary?: Maybe<TargetSalary>;
  inspiringCompanies?: Maybe<Array<Maybe<InspiringCompanyType>>>;
  ResumePreview?: Maybe<Scalars['String']>;
  attachmentPreview?: Maybe<Scalars['String']>;
};

export type CandidateListingProfileForPopUp = {
  __typename?: 'CandidateListingProfileForPopUp';
  _id?: Maybe<Scalars['String']>;
  matchId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  currentLocation?: Maybe<Location>;
  profilePicture?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  appliedJobs?: Maybe<Array<Maybe<Scalars['String']>>>;
  company?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  response?: Maybe<Response>;
  user?: Maybe<User>;
  companyData?: Maybe<Company>;
  jobData?: Maybe<Job>;
};

export type CandidateTargetLocation = {
  __typename?: 'CandidateTargetLocation';
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  _id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  confidentialdescription?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  sector?: Maybe<SectorType>;
  companySize?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  users?: Maybe<Array<RecruiterUser>>;
  jobTitle?: Maybe<Scalars['String']>;
  jobs?: Maybe<Array<Job>>;
  totalJobs?: Maybe<Scalars['Int']>;
  facebook?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<RecruiterUser>;
  owner?: Maybe<RecruiterUser>;
  favouritedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type CompanyCreateInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  confidentialdescription?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  sector: Scalars['String'];
  companySize: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
};

export type CompanyView = {
  __typename?: 'CompanyView';
  name?: Maybe<Scalars['String']>;
};

export type Compensation = {
  __typename?: 'Compensation';
  base?: Maybe<Scalars['String']>;
  onTargetEarnings?: Maybe<Scalars['String']>;
  shortTermIncentives?: Maybe<Scalars['String']>;
  longTermIncentives?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
};

export type CompensationInput = {
  base?: Maybe<Scalars['String']>;
  onTargetEarnings?: Maybe<Scalars['String']>;
  shortTermIncentives?: Maybe<Scalars['String']>;
  longTermIncentives?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
};

export type ContractType = {
  __typename?: 'ContractType';
  _id: Scalars['ID'];
  name: Scalars['String'];
};

export type Country = {
  __typename?: 'Country';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type CreateTagParams = {
  name: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type Currency = {
  __typename?: 'Currency';
  _id: Scalars['ID'];
  name: Scalars['String'];
  symbol: Scalars['String'];
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  _id: Scalars['ID'];
  email: Scalars['String'];
};

export type ExperienceCommentData = {
  __typename?: 'ExperienceCommentData';
  candidateId?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  reasonForChange?: Maybe<Scalars['String']>;
};

export type FamousLeadersInput = {
  id: Scalars['ID'];
  leaderImage?: Maybe<Scalars['String']>;
  leaderName?: Maybe<Scalars['String']>;
  leaderTitle?: Maybe<Scalars['String']>;
  leaderQuestion?: Maybe<Scalars['String']>;
  videoCompleted?: Maybe<Scalars['Boolean']>;
  videoURL?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type FirebaseTimeStamp = {
  nanoseconds?: Maybe<Scalars['Int']>;
  seconds?: Maybe<Scalars['Int']>;
};

export type ForgotPasswordReaponse = {
  __typename?: 'ForgotPasswordReaponse';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type History = {
  __typename?: 'History';
  _id?: Maybe<Scalars['String']>;
  toolName?: Maybe<Scalars['String']>;
  messages?: Maybe<Array<Maybe<Message>>>;
  userId?: Maybe<Scalars['String']>;
  totalUsage?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  isRequestedForTokens?: Maybe<Scalars['Boolean']>;
};

export type HistoryData = {
  __typename?: 'HistoryData';
  chatHistoryForSingleTool?: Maybe<History>;
  chatHistoryForAllTools?: Maybe<Array<Maybe<History>>>;
};

export type InspiringCompanyInput = {
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type InspiringCompanyType = {
  __typename?: 'InspiringCompanyType';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};


export type Job = {
  __typename?: 'Job';
  _id: Scalars['ID'];
  title: Scalars['String'];
  hideSalary: Scalars['Boolean'];
  countries: Array<Country>;
  salaryPackage: Scalars['String'];
  experience?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  requiredSkills: Array<Skills>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  recruiters?: Maybe<Array<Maybe<RecruiterUser>>>;
  recruitersDetail?: Maybe<Array<Maybe<RecruiterUser>>>;
  leadRecruiter?: Maybe<RecruiterUser>;
  adminRecruiter?: Maybe<RecruiterBoUser>;
  fields: Array<Maybe<JobField>>;
  contractType: ContractType;
  createdAt?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<RecruiterUser>;
  status?: Maybe<Scalars['String']>;
  targetCompanies: Array<InspiringCompany>;
  candidates?: Maybe<Array<Match>>;
  leftContent?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  rightContent?: Maybe<Scalars['String']>;
  isAnonymous?: Maybe<Scalars['Boolean']>;
  referral?: Maybe<Referral>;
  AnonymousCompanyTitle?: Maybe<Scalars['String']>;
  AnonymousCompanyDescription?: Maybe<Scalars['String']>;
  matchs?: Maybe<Match>;
  banner?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  mobileBanner?: Maybe<Scalars['String']>;
  newCandidates?: Maybe<Array<Maybe<MatchAllJobs>>>;
  favouriteBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  isConfidential?: Maybe<Scalars['Boolean']>;
  new_candidates?: Maybe<Array<Maybe<Match>>>;
  count?: Maybe<Scalars['Int']>;
  assessment?: Maybe<AssessmentView>;
  yearlyCompensation?: Maybe<YearlyCompensationInputJobView>;
  progressReport?: Maybe<Scalars['String']>;
  totalJobs?: Maybe<Scalars['Int']>;
};

export type JobField = {
  __typename?: 'JobField';
  _id: Scalars['ID'];
  title: Scalars['String'];
  parent?: Maybe<JobField>;
};

export type JobId = {
  __typename?: 'JobId';
  jobId?: Maybe<Scalars['String']>;
};

export type JobIsFavouriteParams = {
  isFavourite: Scalars['Boolean'];
};

export type JobLandingPage = {
  __typename?: 'JobLandingPage';
  _id: Scalars['ID'];
  jobId?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  leftContent?: Maybe<Scalars['String']>;
  rightContent?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  customCss?: Maybe<Scalars['String']>;
  cssLink?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  faviconUrl?: Maybe<Scalars['String']>;
  metaImageUrl?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<RecruiterUser>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type JobLandingPageStatusParams = {
  status: Scalars['String'];
};

export type JobStatusParams = {
  status: Scalars['String'];
};

export type JobView = {
  __typename?: 'JobView';
  _id: Scalars['ID'];
  title: Scalars['String'];
  company?: Maybe<CompanyView>;
  new_candidates?: Maybe<Array<Maybe<Match>>>;
};

export type JobsCount = {
  __typename?: 'JobsCount';
  activeJobsCount?: Maybe<Scalars['String']>;
  archivedJobsCount?: Maybe<Scalars['String']>;
  pendingJobsCount?: Maybe<Scalars['String']>;
  rejectedJobsCount?: Maybe<Scalars['String']>;
};

export type Languages = {
  __typename?: 'Languages';
  language?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
};

export type LanguagesInput = {
  language?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
};

export type LocationFields = {
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type Match = {
  __typename?: 'Match';
  _id: Scalars['ID'];
  candidateListing?: Maybe<CandidateListing>;
  jobListing?: Maybe<Job>;
  benchmark?: Maybe<Scalars['String']>;
  showCurrentCompensation?: Maybe<Scalars['Boolean']>;
  showExpectedCompensation?: Maybe<Scalars['Boolean']>;
  nextSteps?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  score?: Maybe<Scalars['Int']>;
  jobScore?: Maybe<Scalars['Float']>;
  activationScore?: Maybe<Scalars['Int']>;
  recruiterAction?: Maybe<Scalars['String']>;
  candidateAction?: Maybe<Scalars['String']>;
  lastAction?: Maybe<Scalars['String']>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  bypass?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  alreadyApplied?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  candidateScore?: Maybe<Scalars['Float']>;
  matchingAlgoScores?: Maybe<Array<Maybe<MatchingAlgorithmScores>>>;
  response?: Maybe<Response>;
  AssessmentReport?: Maybe<GetAssessments>;
  hidePersonalDetails?: Maybe<Scalars['Boolean']>;
};

export type MatchAllJobs = {
  __typename?: 'MatchAllJobs';
  _id: Scalars['ID'];
  candidateListing?: Maybe<CandidateListing>;
  jobListing?: Maybe<Job>;
  company?: Maybe<Company>;
  score?: Maybe<Scalars['Int']>;
  jobScore?: Maybe<Scalars['Float']>;
  activationScore?: Maybe<Scalars['Int']>;
  recruiterAction?: Maybe<Scalars['String']>;
  candidateAction?: Maybe<Scalars['String']>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  bypass?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  alreadyApplied?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  response?: Maybe<Response>;
  favouriteBy?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MatchingAlgorithmScores = {
  __typename?: 'MatchingAlgorithmScores';
  candidateScore?: Maybe<Scalars['Float']>;
  jobScore?: Maybe<Scalars['Float']>;
};

export type Message = {
  __typename?: 'Message';
  _id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  payload?: Maybe<Scalars['String']>;
  timestamp: Scalars['Float'];
  response?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  senderMail: Scalars['String'];
  receiverMail: Scalars['String'];
  users?: Maybe<Array<Maybe<User>>>;
};

export type MessagePayload = {
  type?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  matchId?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['String']>;
  recruiterId?: Maybe<Scalars['String']>;
  candidateId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  timeStampOfMyLastMessage?: Maybe<FirebaseTimeStamp>;
};

export type MobileUser = {
  __typename?: 'MobileUser';
  _id: Scalars['ID'];
  fullName?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  email?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  candidate?: Maybe<CandidateListing>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  experiences?: Maybe<Array<Maybe<CandidateExperience>>>;
  educations?: Maybe<Array<Maybe<CandidateEducation>>>;
  response?: Maybe<Response>;
  source?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  updateCandidateBenchmark?: Maybe<CandidateListingProfile>;
  updateCandidateShowCompensation?: Maybe<Match>;
  updateCandidateNextSteps?: Maybe<Match>;
  updateCandidatePersonalInfo?: Maybe<CandidateListingProfile>;
  createMatch?: Maybe<Match>;
  AssessmentsUpdate?: Maybe<GetAssessments>;
  activateCandidate?: Maybe<Match>;
  candidateBenchmark?: Maybe<Response>;
  candidateShowCompensation?: Maybe<Response>;
  candidateHidePersonalDetails?: Maybe<Response>;
  candidateNextSteps?: Maybe<Response>;
  recruiterAction?: Maybe<Match>;
  testEmailTrigger?: Maybe<Response>;
  messageTriggerMailRecruiter?: Maybe<Response>;
  generateText?: Maybe<GenerateTextResponse>;
  deleteSingleHistory?: Maybe<Response>;
  deleteHistory?: Maybe<Response>;
  updateAiUserProfile?: Maybe<AiUserProfile>;
  updateProfileWithLinkedin?: Maybe<AiUserProfile>;
  handleLogin?: Maybe<MobileUser>;
  createEvent?: Maybe<Event>;
  editEvent?: Maybe<Response>;
  updateFamousLeaderInfo?: Maybe<FamousLeaders>;
  addNewCompany?: Maybe<InspiringCompany>;
  generateBlinks?: Maybe<Blink>;
  saveReferral?: Maybe<Response>;
  getUserReferrals?: Maybe<Array<Maybe<Referral>>>;
  registerCompany?: Maybe<Company>;
  updateCompany?: Maybe<Company>;
  createCountry?: Maybe<Array<Maybe<Country>>>;
  createJobLandingPage?: Maybe<JobLandingPage>;
  createJob?: Maybe<Job>;
  updateJobStatus?: Maybe<Job>;
  updateJobIsFavourite?: Maybe<Job>;
  deleteJob?: Maybe<Job>;
  resetJobCards?: Maybe<ResetJobCards>;
  createUser: User;
  updateUser: User;
  deleteUser: Scalars['Boolean'];
  userTyping: Scalars['Boolean'];
  createMessage: Message;
  updateMessage: Message;
  deleteMessage: Scalars['Boolean'];
  AttachRecruiterToACompany?: Maybe<RecruiterUser>;
  AttachRecruiterToAJob?: Maybe<Response>;
  removeRecruiterFromACompany?: Maybe<RecruiterUser>;
  createSkills: Array<Skills>;
  updateSkill?: Maybe<Skills>;
  updateStatus?: Maybe<Skills>;
  createCandidateAndMatch?: Maybe<CreateCandidateAndMatchResponse>;
  adminSignup?: Maybe<AdminUser>;
  adminLogin?: Maybe<AdminUser>;
  userSignup?: Maybe<User>;
  loginUser?: Maybe<User>;
  registerAsRecruiter?: Maybe<RecruiterUser>;
  resetAiUserPassword?: Maybe<Response>;
  verifyAiUserResetPasswordToken?: Maybe<Response>;
  updateAiUserPassword?: Maybe<Response>;
  refillAiUserTokens?: Maybe<Response>;
  candidateSignup?: Maybe<User>;
  candidateLogin?: Maybe<User>;
  updateCandidate?: Maybe<CandidateListing>;
  createCandidate?: Maybe<Response>;
  forgetPassword?: Maybe<ForgotPasswordReaponse>;
  resetPassword?: Maybe<Response>;
  resetEmail?: Maybe<Response>;
  verifyResetCode?: Maybe<ForgotPasswordReaponse>;
  favouriteJob?: Maybe<Response>;
  removeFavouriteJob?: Maybe<Response>;
  checkExistingUser?: Maybe<ExistingUserResponse>;
  updateCandidateDocument?: Maybe<CandidateListing>;
  registerCandidateStep?: Maybe<CandidateListing>;
  recruiterSignup?: Maybe<RecruiterUser>;
  VerifyRecruiterSignup?: Maybe<Response>;
  updateRecruiter?: Maybe<RecruiterUser>;
  recruiterSignupForACompany?: Maybe<RecruiterUser>;
  recruiterLogin?: Maybe<RecruiterUser>;
  recruiterResetPassword?: Maybe<RecruiterUser>;
  sendPasswordResetEmail?: Maybe<Response>;
  updateUserFCMToken?: Maybe<Response>;
  deleteUserFCMToken?: Maybe<Response>;
  sendMessage?: Maybe<Response>;
  deleteAUser?: Maybe<Response>;
  createTag: Array<Tag>;
  setNotificationToRead?: Maybe<NotificationId>;
  setMultipleNotificationsToRead?: Maybe<AllNotificationToRead>;
  sendRecruiterComment?: Maybe<JobId>;
  tagCandidates?: Maybe<Array<Maybe<Tag>>>;
  tagCrawlies?: Maybe<Array<Maybe<Tag>>>;
  deleteTag?: Maybe<Tag>;
  editTag?: Maybe<Tag>;
  untagCandidate?: Maybe<Response>;
  untagCrawlies?: Maybe<Response>;
};


export type MutationUpdateCandidateBenchmarkArgs = {
  candidateId: Scalars['String'];
  benchMark?: Maybe<Scalars['String']>;
};


export type MutationUpdateCandidateShowCompensationArgs = {
  candidateId: Scalars['String'];
  showCurrentCompensation?: Maybe<Scalars['Boolean']>;
  showExpectedCompensation?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateCandidateNextStepsArgs = {
  candidateId: Scalars['String'];
  nextSteps?: Maybe<Scalars['String']>;
};


export type MutationUpdateCandidatePersonalInfoArgs = {
  candidateId: Scalars['String'];
  personalInformation?: Maybe<Scalars['String']>;
};


export type MutationCreateMatchArgs = {
  jobId?: Maybe<Scalars['String']>;
  candidateId?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['String']>;
  dislikeReason?: Maybe<Scalars['String']>;
  isDesktop?: Maybe<Scalars['Boolean']>;
};


export type MutationAssessmentsUpdateArgs = {
  matchId?: Maybe<Scalars['String']>;
  hideExperience?: Maybe<Scalars['Boolean']>;
  hideTraits?: Maybe<Scalars['Boolean']>;
  hideDrivers?: Maybe<Scalars['Boolean']>;
  experience?: Maybe<Array<Maybe<AssessmentInputView>>>;
  traits?: Maybe<Array<Maybe<AssessmentInputView>>>;
  drivers?: Maybe<Array<Maybe<AssessmentInputView>>>;
};


export type MutationActivateCandidateArgs = {
  matchId?: Maybe<Scalars['String']>;
  activationScore?: Maybe<Scalars['Int']>;
};


export type MutationCandidateBenchmarkArgs = {
  matchId?: Maybe<Scalars['String']>;
  benchmark?: Maybe<Scalars['String']>;
};


export type MutationCandidateShowCompensationArgs = {
  matchId?: Maybe<Scalars['String']>;
  showCurrentCompensation?: Maybe<Scalars['Boolean']>;
  showExpectedCompensation?: Maybe<Scalars['Boolean']>;
};


export type MutationCandidateHidePersonalDetailsArgs = {
  matchId?: Maybe<Scalars['String']>;
  hidePersonalDetails?: Maybe<Scalars['Boolean']>;
};


export type MutationCandidateNextStepsArgs = {
  matchId?: Maybe<Scalars['String']>;
  nextSteps?: Maybe<Scalars['String']>;
};


export type MutationRecruiterActionArgs = {
  matchId?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  lastAction?: Maybe<Scalars['String']>;
};


export type MutationTestEmailTriggerArgs = {
  jobId?: Maybe<Scalars['String']>;
};


export type MutationMessageTriggerMailRecruiterArgs = {
  matchId?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};


export type MutationGenerateTextArgs = {
  prompt?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  toolName?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type MutationDeleteSingleHistoryArgs = {
  historyId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationDeleteHistoryArgs = {
  toolName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationUpdateAiUserProfileArgs = {
  userId: Scalars['String'];
  data?: Maybe<UpdateAiUserData>;
};


export type MutationUpdateProfileWithLinkedinArgs = {
  userId?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
};


export type MutationHandleLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateEventArgs = {
  params?: Maybe<EventInput>;
};


export type MutationEditEventArgs = {
  params?: Maybe<EventInput>;
  eventId?: Maybe<Scalars['String']>;
};


export type MutationUpdateFamousLeaderInfoArgs = {
  params?: Maybe<FamousLeadersInput>;
};


export type MutationAddNewCompanyArgs = {
  name: Scalars['String'];
};


export type MutationGenerateBlinksArgs = {
  url?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
};


export type MutationSaveReferralArgs = {
  jobId?: Maybe<Scalars['String']>;
  referral?: Maybe<Scalars['Boolean']>;
  referreeId?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationGetUserReferralsArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationRegisterCompanyArgs = {
  companyData?: Maybe<CompanyCreateInput>;
  companyId?: Maybe<Scalars['String']>;
};


export type MutationUpdateCompanyArgs = {
  companyData?: Maybe<UpdateCompany>;
  companyId?: Maybe<Scalars['String']>;
};


export type MutationCreateCountryArgs = {
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationCreateJobLandingPageArgs = {
  jobLandingPage?: Maybe<CreateJobLandingPageParams>;
  jobLandingPageId?: Maybe<Scalars['String']>;
};


export type MutationCreateJobArgs = {
  job?: Maybe<CreateJobParams>;
  jobId?: Maybe<Scalars['String']>;
  isAnonymous?: Maybe<Scalars['Boolean']>;
  isDraft?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateJobStatusArgs = {
  jobId?: Maybe<Scalars['String']>;
  action?: Maybe<JobStatusParams>;
};


export type MutationUpdateJobIsFavouriteArgs = {
  jobId?: Maybe<Scalars['String']>;
  action?: Maybe<JobIsFavouriteParams>;
};


export type MutationDeleteJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};


export type MutationResetJobCardsArgs = {
  candidateId?: Maybe<Scalars['String']>;
};


export type MutationCreateUserArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  email: Scalars['String'];
};


export type MutationUserTypingArgs = {
  email: Scalars['String'];
  receiverMail: Scalars['String'];
};


export type MutationCreateMessageArgs = {
  senderMail: Scalars['String'];
  receiverMail: Scalars['String'];
  message: Scalars['String'];
  timestamp: Scalars['Float'];
};


export type MutationUpdateMessageArgs = {
  id: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationDeleteMessageArgs = {
  id: Scalars['String'];
};


export type MutationAttachRecruiterToACompanyArgs = {
  userId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};


export type MutationAttachRecruiterToAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationRemoveRecruiterFromACompanyArgs = {
  userId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};


export type MutationCreateSkillsArgs = {
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateSkillArgs = {
  skillId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};


export type MutationUpdateStatusArgs = {
  skillId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateCandidateAndMatchArgs = {
  jobId: Scalars['String'];
  candidateInfo?: Maybe<CandidateInfoInput>;
  media: Scalars['String'];
  action?: Maybe<Scalars['String']>;
};


export type MutationAdminSignupArgs = {
  fullName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  title: Scalars['String'];
};


export type MutationAdminLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUserSignupArgs = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  roles: Array<Scalars['String']>;
  languagePreference?: Maybe<Scalars['String']>;
  linkedInProfileUrl?: Maybe<Scalars['String']>;
  currentCompany?: Maybe<Scalars['String']>;
  currentJobTitle?: Maybe<Scalars['String']>;
};


export type MutationLoginUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRegisterAsRecruiterArgs = {
  userId: Scalars['String'];
  recruiter: Recruiter;
};


export type MutationResetAiUserPasswordArgs = {
  email: Scalars['String'];
};


export type MutationVerifyAiUserResetPasswordTokenArgs = {
  token: Scalars['String'];
};


export type MutationUpdateAiUserPasswordArgs = {
  token: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationRefillAiUserTokensArgs = {
  email: Scalars['String'];
};


export type MutationCandidateSignupArgs = {
  fullName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  isAiUser?: Maybe<Scalars['Boolean']>;
  roles: Array<Scalars['String']>;
};


export type MutationCandidateLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  isAiUser?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateCandidateArgs = {
  candidateId: Scalars['String'];
  candidate?: Maybe<UpdateCandidate>;
  createdBy?: Maybe<Scalars['String']>;
};


export type MutationCreateCandidateArgs = {
  candidate?: Maybe<RegisterCandidate>;
  isAiUser?: Maybe<Scalars['Boolean']>;
};


export type MutationForgetPasswordArgs = {
  email: Scalars['String'];
  isAiUser?: Maybe<Scalars['Boolean']>;
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
  resetCode: Scalars['Int'];
  newPassword: Scalars['String'];
  bypassResetCode?: Maybe<Scalars['Boolean']>;
  isAiUser?: Maybe<Scalars['Boolean']>;
};


export type MutationResetEmailArgs = {
  email: Scalars['String'];
  newEmail: Scalars['String'];
};


export type MutationVerifyResetCodeArgs = {
  email: Scalars['String'];
  resetCode: Scalars['String'];
  isAiUser?: Maybe<Scalars['Boolean']>;
};


export type MutationFavouriteJobArgs = {
  jobId: Scalars['String'];
  candidateId?: Maybe<Scalars['String']>;
};


export type MutationRemoveFavouriteJobArgs = {
  jobId: Scalars['String'];
  candidateId?: Maybe<Scalars['String']>;
};


export type MutationCheckExistingUserArgs = {
  email?: Maybe<Scalars['String']>;
  isAiUser?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateCandidateDocumentArgs = {
  candidateId: Scalars['String'];
  candidate?: Maybe<UpdateCandidateDocument>;
};


export type MutationRegisterCandidateStepArgs = {
  candidateId: Scalars['String'];
  candidate?: Maybe<RegisterCandidateStepType>;
  isAiUser?: Maybe<Scalars['Boolean']>;
};


export type MutationRecruiterSignupArgs = {
  fullName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  title: Scalars['String'];
  company: Scalars['String'];
  location: UpdateLocationExperienceAndEducation;
  isAiUser?: Maybe<Scalars['Boolean']>;
};


export type MutationVerifyRecruiterSignupArgs = {
  userId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  isAiUser?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateRecruiterArgs = {
  recruiterId: Scalars['String'];
  recruiter?: Maybe<UpdateRecruiter>;
};


export type MutationRecruiterSignupForACompanyArgs = {
  fullName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  title: Scalars['String'];
  companyId: Scalars['String'];
  isAiUser?: Maybe<Scalars['Boolean']>;
};


export type MutationRecruiterLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  isAiUser?: Maybe<Scalars['Boolean']>;
};


export type MutationRecruiterResetPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  isAiUser?: Maybe<Scalars['Boolean']>;
};


export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String'];
  isAiUser?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateUserFcmTokenArgs = {
  token: Scalars['String'];
  appType: Scalars['String'];
};


export type MutationDeleteUserFcmTokenArgs = {
  appType: Scalars['String'];
};


export type MutationSendMessageArgs = {
  sendTo: Scalars['String'];
  myRole: Scalars['String'];
  message: Scalars['String'];
  payload?: Maybe<MessagePayload>;
};


export type MutationDeleteAUserArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type MutationCreateTagArgs = {
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationSetNotificationToReadArgs = {
  notificationId?: Maybe<Scalars['String']>;
};


export type MutationSetMultipleNotificationsToReadArgs = {
  notificationsArray?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationSendRecruiterCommentArgs = {
  senderId?: Maybe<Scalars['String']>;
  matchId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type MutationTagCandidatesArgs = {
  candidateIds: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};


export type MutationTagCrawliesArgs = {
  candidateIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tags?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationDeleteTagArgs = {
  _id?: Maybe<Scalars['ID']>;
};


export type MutationEditTagArgs = {
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};


export type MutationUntagCandidateArgs = {
  tagId?: Maybe<Scalars['ID']>;
  candidateId?: Maybe<Scalars['ID']>;
};


export type MutationUntagCrawliesArgs = {
  tagId?: Maybe<Scalars['ID']>;
  candidateId?: Maybe<Scalars['ID']>;
};

export type NotificationId = {
  __typename?: 'NotificationId';
  notificationId?: Maybe<Scalars['String']>;
};

export type NotificationObject = {
  __typename?: 'NotificationObject';
  _id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['String']>;
  matchId?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  notificationHeader?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  tabActivity?: Maybe<Scalars['String']>;
  job?: Maybe<Job>;
  candidate?: Maybe<CandidateListing>;
  user_type?: Maybe<Scalars['String']>;
  candidateId?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  recruiter?: Maybe<RecruiterUser>;
  match?: Maybe<Match>;
};

export type Objectives = {
  __typename?: 'Objectives';
  _id: Scalars['ID'];
  title: Scalars['String'];
};

export type ProfileUserInput = {
  email?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  getMatch?: Maybe<Match>;
  getAllMatch: Array<GetAllMatchData>;
  matchesForAJob: Array<Match>;
  newCandidatesAgainstAJob: Array<Match>;
  disLikesAgainstAJob: Array<Match>;
  dislikeActivityOfRecruiter: Array<Match>;
  likesAgainstAJob: Array<Match>;
  likeActivityOfRecruiter: Array<Match>;
  matchesAgainstAJob: Array<Match>;
  matchesActivityOfRecruiter: Array<Match>;
  selectedAgainstAJob: Array<Match>;
  selectedActivityOfRecruiter: Array<Match>;
  rejectedAgainstAJob: Array<Match>;
  hiredAgainstAJob: Array<Match>;
  favouritesAgainstAJob: Array<Match>;
  candidatesEligableForChatOnAJob: Array<Match>;
  getChatHistory?: Maybe<HistoryData>;
  getSingleHistory?: Maybe<SingleMessage>;
  getAiUserData?: Maybe<AiUserProfile>;
  requestAiTokens?: Maybe<Response>;
  famousLeaderQuery: Array<FamousLeaders>;
  getEvents?: Maybe<Array<Maybe<Event>>>;
  getAllInspiringCompanies?: Maybe<Array<Maybe<InspiringCompany>>>;
  getMatchPopUp?: Maybe<Array<Maybe<CandidateListingProfileForPopUp>>>;
  userCompanies: Array<Company>;
  companies: Array<Company>;
  getSingleCompany?: Maybe<Company>;
  getCompanyFromJob?: Maybe<Company>;
  contracts: Array<ContractType>;
  countries: Array<Country>;
  currencies: Array<Currency>;
  inspiringCompanies?: Maybe<Array<Maybe<InspiringCompany>>>;
  jobFields: Array<JobField>;
  getJobLandingPage?: Maybe<JobLandingPage>;
  getJobDetails?: Maybe<Job>;
  getPublicJobDetails?: Maybe<Job>;
  getUserJobs?: Maybe<Array<Maybe<Job>>>;
  AllJobs?: Maybe<Array<Maybe<Job>>>;
  getMatchAllJob?: Maybe<Array<Maybe<Job>>>;
  AllActiveJobs?: Maybe<Array<Maybe<Job>>>;
  AllArchivedJobs?: Maybe<Array<Maybe<Job>>>;
  AllPendingJobs?: Maybe<Array<Maybe<Job>>>;
  AllRejectedJobs?: Maybe<Array<Maybe<Job>>>;
  AllJobsView?: Maybe<Array<Maybe<JobView>>>;
  getAllRecruiterJobs?: Maybe<Array<Maybe<Job>>>;
  getJobs?: Maybe<Array<Maybe<Job>>>;
  getCompanyJobs?: Maybe<Array<Maybe<Job>>>;
  JobCards?: Maybe<Array<Maybe<Job>>>;
  likedJobs?: Maybe<Array<Maybe<Job>>>;
  disLikedJobs?: Maybe<Array<Maybe<Job>>>;
  matchedJobs?: Maybe<Array<Maybe<Job>>>;
  allTypesOfMatchedJobs?: Maybe<Array<Maybe<Job>>>;
  favouriteJobs?: Maybe<Array<Maybe<Job>>>;
  getCandidateChatsOnJobs?: Maybe<Array<Maybe<Job>>>;
  GetJobsCount?: Maybe<JobsCount>;
  users?: Maybe<Array<Maybe<User>>>;
  messages?: Maybe<Array<Maybe<Message>>>;
  getObjectives: Array<Objectives>;
  getPersonalInterests: Array<PersonalInterest>;
  getAllRecruitersInBO: Array<RecruiterBoUser>;
  getAllRecruiters: Array<RecruiterUser>;
  getAllRecruitersExcept: Array<RecruiterUser>;
  getAllCompanyRecruiters: Array<RecruiterUser>;
  getSingleRecruiter?: Maybe<RecruiterBoUser>;
  sectors?: Maybe<Array<Maybe<SectorType>>>;
  skills: Array<Skills>;
  getCandidate?: Maybe<CandidateListingProfile>;
  getAllCandidatesInBO?: Maybe<Array<Maybe<CandidateList>>>;
  countAllCandidatesInBO?: Maybe<CountAllCandidates>;
  currentUser?: Maybe<CurrentUser>;
  allAdmins: Array<Maybe<AdminUser>>;
  Objectives?: Maybe<Array<Maybe<ObjectivesOrInterests>>>;
  canidateInfo?: Maybe<User>;
  ProfessionalInterests?: Maybe<Array<Maybe<ObjectivesOrInterests>>>;
  allCandidates: Array<Maybe<CandidateListing>>;
  me?: Maybe<RecruiterUser>;
  allRecruiters: Array<Maybe<RecruiterUser>>;
  getTags?: Maybe<Array<Maybe<Tag>>>;
  getMyNotifications?: Maybe<Array<Maybe<NotificationObject>>>;
  crawliesPersonalDetails?: Maybe<Array<Maybe<CrawliesPersonalDetail>>>;
  jobListingsModel?: Maybe<Array<Maybe<JobListingsModel>>>;
};


export type QueryGetMatchArgs = {
  matchId?: Maybe<Scalars['String']>;
};


export type QueryGetAllMatchArgs = {
  ListingId?: Maybe<Scalars['String']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
};


export type QueryMatchesForAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
  searchParams?: Maybe<SearchParamsInput>;
};


export type QueryNewCandidatesAgainstAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};


export type QueryDisLikesAgainstAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};


export type QueryLikesAgainstAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};


export type QueryMatchesAgainstAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};


export type QuerySelectedAgainstAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};


export type QueryRejectedAgainstAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};


export type QueryHiredAgainstAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};


export type QueryFavouritesAgainstAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};


export type QueryCandidatesEligableForChatOnAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};


export type QueryGetChatHistoryArgs = {
  userId?: Maybe<Scalars['String']>;
  toolName?: Maybe<Scalars['String']>;
};


export type QueryGetSingleHistoryArgs = {
  historyId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type QueryGetAiUserDataArgs = {
  userId: Scalars['String'];
};


export type QueryRequestAiTokensArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryGetEventsArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type QueryGetMatchPopUpArgs = {
  candidateId?: Maybe<Array<Maybe<CandidateIds>>>;
};


export type QueryCompaniesArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
};


export type QueryGetSingleCompanyArgs = {
  companyId?: Maybe<Scalars['String']>;
};


export type QueryGetCompanyFromJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};


export type QueryGetJobLandingPageArgs = {
  jobId: Scalars['ID'];
};


export type QueryGetJobDetailsArgs = {
  jobId: Scalars['ID'];
};


export type QueryGetPublicJobDetailsArgs = {
  jobId: Scalars['ID'];
};


export type QueryGetUserJobsArgs = {
  userId?: Maybe<Scalars['String']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};


export type QueryAllActiveJobsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type QueryAllArchivedJobsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
};


export type QueryAllPendingJobsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
};


export type QueryAllRejectedJobsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
};


export type QueryGetJobsArgs = {
  companyId: Scalars['ID'];
};


export type QueryGetCompanyJobsArgs = {
  companyId: Scalars['ID'];
};


export type QueryJobCardsArgs = {
  candidateId?: Maybe<Scalars['String']>;
  pageNumber?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
};


export type QueryLikedJobsArgs = {
  candidateId?: Maybe<Scalars['String']>;
};


export type QueryDisLikedJobsArgs = {
  candidateId?: Maybe<Scalars['String']>;
};


export type QueryMatchedJobsArgs = {
  candidateId?: Maybe<Scalars['String']>;
};


export type QueryAllTypesOfMatchedJobsArgs = {
  candidateId?: Maybe<Scalars['String']>;
};


export type QueryFavouriteJobsArgs = {
  candidateId?: Maybe<Scalars['String']>;
};


export type QueryGetCandidateChatsOnJobsArgs = {
  candidateId?: Maybe<Scalars['String']>;
};


export type QueryGetJobsCountArgs = {
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
};


export type QueryGetAllRecruitersExceptArgs = {
  companyId?: Maybe<Scalars['String']>;
};


export type QueryGetAllCompanyRecruitersArgs = {
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetSingleRecruiterArgs = {
  recruiterId?: Maybe<Scalars['String']>;
};


export type QuerySkillsArgs = {
  status?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isBO?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCandidateArgs = {
  candidateId: Scalars['String'];
};


export type QueryGetAllCandidatesInBoArgs = {
  searchParams?: Maybe<SearchParamsInput>;
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
};


export type QueryCountAllCandidatesInBoArgs = {
  searchParams?: Maybe<SearchParamsInput>;
};


export type QueryCanidateInfoArgs = {
  id: Scalars['String'];
};


export type QueryGetMyNotificationsArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type QueryCrawliesPersonalDetailsArgs = {
  pageNumber?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryJobListingsModelArgs = {
  currencyId?: Maybe<Scalars['String']>;
  jobLandingPageId?: Maybe<Scalars['String']>;
};

export type Recruiter = {
  fullName: Scalars['String'];
  title: Scalars['String'];
  profilePic: Scalars['String'];
  coverPhoto?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  location: LocationType;
  company: Scalars['String'];
  description: Scalars['String'];
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
  educations?: Maybe<Array<Maybe<EducationInput>>>;
  languages?: Maybe<Array<Maybe<LanguagesInput>>>;
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
};

export type RecruiterBoUser = {
  __typename?: 'RecruiterBOUser';
  _id: Scalars['ID'];
  fullName?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  user?: Maybe<UserObj>;
  title?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  companiesCount?: Maybe<Scalars['Int']>;
  jobsCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  experiences?: Maybe<Array<Maybe<RecruiterExperience>>>;
  educations?: Maybe<Array<Maybe<RecruiterEducation>>>;
  coverPhoto?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Languages>>>;
  location?: Maybe<LocationTypeEducationAndExperience>;
};

export type RecruiterCreateInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  sector: Scalars['String'];
  companySize: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
};

export type RecruiterEducation = {
  __typename?: 'RecruiterEducation';
  _id?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  degree?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Boolean']>;
  endYear?: Maybe<Scalars['String']>;
  location?: Maybe<LocationTypeEducationAndExperience>;
  logo?: Maybe<Scalars['String']>;
  mergedData?: Maybe<Array<Maybe<RecruiterEducation>>>;
};

export type RecruiterExperience = {
  __typename?: 'RecruiterExperience';
  _id?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
  endYear?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Boolean']>;
  sector?: Maybe<Scalars['String']>;
  jobField?: Maybe<Array<Maybe<Scalars['String']>>>;
  location?: Maybe<LocationTypeEducationAndExperience>;
  logo?: Maybe<Scalars['String']>;
  mergedData?: Maybe<Array<Maybe<RecruiterExperience>>>;
};

export type RecruiterUser = {
  __typename?: 'RecruiterUser';
  _id: Scalars['ID'];
  fullName?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  email?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  location?: Maybe<LocationQuery>;
  verified?: Maybe<Scalars['Boolean']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  experiences?: Maybe<Array<Maybe<RecruiterExperience>>>;
  educations?: Maybe<Array<Maybe<RecruiterEducation>>>;
  token?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  response?: Maybe<Response>;
  profileVideo?: Maybe<Scalars['String']>;
  languagePreference?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  user?: Maybe<UserProfileUserOutput>;
};

export type RegisterCandidate = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  totalExperience?: Maybe<Scalars['Int']>;
  yearlyCompensation?: Maybe<YearlyCompensationInput>;
  nextSteps?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<LanguagesInput>>>;
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
  resume?: Maybe<Scalars['String']>;
  education?: Maybe<Array<Maybe<EducationInput>>>;
  isPublic?: Maybe<Scalars['Boolean']>;
  currentLocation?: Maybe<LocationInput>;
  profilePicture?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  objectives?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetLocations?: Maybe<Array<Maybe<Scalars['String']>>>;
  professionalInterests?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  desiredSalaryPackage?: Maybe<Scalars['String']>;
  currentSalaryPackage?: Maybe<Scalars['String']>;
  isOnlyNetworker?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  jobField?: Maybe<Array<Maybe<Scalars['String']>>>;
  appliedJobs?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<TagsInput>>>;
  contractIdType?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  recruiterVisible?: Maybe<Scalars['Boolean']>;
  resumeName?: Maybe<Scalars['String']>;
  coverLetterName?: Maybe<Scalars['String']>;
};

export type RegisterCandidateStepType = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  currentLocation?: Maybe<LocationInputRegisterationStep>;
  company?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  objectives?: Maybe<Array<Maybe<Scalars['String']>>>;
  professionalInterests?: Maybe<Array<Maybe<Scalars['String']>>>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSkills?: Maybe<Array<Maybe<Scalars['String']>>>;
  customTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileVideo?: Maybe<Scalars['String']>;
  registrationStepNo?: Maybe<Scalars['Int']>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
};

export type ResetJobCards = {
  __typename?: 'ResetJobCards';
  message?: Maybe<Scalars['String']>;
};

export type Response = {
  __typename?: 'Response';
  message?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type SearchParamsInput = {
  searchText?: Maybe<Scalars['String']>;
  searchType?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  dtFrom?: Maybe<Scalars['String']>;
  dtTo?: Maybe<Scalars['String']>;
};

export type SectorType = {
  __typename?: 'SectorType';
  _id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type SectorsInput = {
  _id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type SectorsType = {
  __typename?: 'SectorsType';
  _id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type SingleMessage = {
  __typename?: 'SingleMessage';
  _id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
  timestamp: Scalars['Float'];
  response?: Maybe<Scalars['String']>;
};

export type Skills = {
  __typename?: 'Skills';
  _id: Scalars['ID'];
  title: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
  jobsCount?: Maybe<Array<Maybe<Job>>>;
  candidatesCount?: Maybe<Array<Maybe<CandidateListing>>>;
  createdAt?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  createdMatch?: Maybe<Match>;
  companyCreated?: Maybe<Company>;
  newMessage?: Maybe<Message>;
  newUser?: Maybe<User>;
  oldUser?: Maybe<Scalars['String']>;
  userTyping?: Maybe<Scalars['String']>;
  sendNewNotification?: Maybe<SubscriptionPayload>;
};


export type SubscriptionNewMessageArgs = {
  receiverMail: Scalars['String'];
};


export type SubscriptionUserTypingArgs = {
  receiverMail: Scalars['String'];
};

export type SubscriptionPayload = {
  __typename?: 'SubscriptionPayload';
  notificationForId?: Maybe<Scalars['String']>;
  latestNotification?: Maybe<NotificationObject>;
};

export type Tag = {
  __typename?: 'Tag';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type TagsInput = {
  _id?: Maybe<Scalars['ID']>;
};

export type TargetLocations = {
  __typename?: 'TargetLocations';
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  _id: Scalars['ID'];
};

export type TargetLocationsInput = {
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
};

export type TargetSalary = {
  __typename?: 'TargetSalary';
  label?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  range?: Maybe<Scalars['Int']>;
  targetSalaryMin?: Maybe<Scalars['Int']>;
  targetSalaryMax?: Maybe<Scalars['Int']>;
  visibleToRecruiter?: Maybe<Scalars['Boolean']>;
  _id: Scalars['ID'];
};

export type TargetSalaryInput = {
  label?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  range?: Maybe<Scalars['Int']>;
  targetSalaryMin?: Maybe<Scalars['Int']>;
  targetSalaryMax?: Maybe<Scalars['Int']>;
  visibleToRecruiter?: Maybe<Scalars['Boolean']>;
};

export type UpdateCandidate = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  personalInformation?: Maybe<Scalars['String']>;
  benchMark?: Maybe<Scalars['String']>;
  totalExperience?: Maybe<Scalars['Int']>;
  yearlyCompensation?: Maybe<YearlyCompensationInput>;
  languages?: Maybe<Array<Maybe<LanguagesInput>>>;
  languageComments?: Maybe<Scalars['String']>;
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
  title?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  education?: Maybe<Array<Maybe<EducationInput>>>;
  educationComment?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  currentLocation?: Maybe<LocationInput>;
  profilePicture?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  jobField?: Maybe<Array<Maybe<Scalars['String']>>>;
  objectives?: Maybe<Array<Maybe<SectorsInput>>>;
  targetLocations?: Maybe<Array<Maybe<TargetLocationsInput>>>;
  sectors?: Maybe<Array<Maybe<SectorsInput>>>;
  professionalInterests?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** targetLocations: [String] */
  targetRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  desiredSalaryPackage?: Maybe<Scalars['String']>;
  currentSalaryPackage?: Maybe<Scalars['String']>;
  isOnlyNetworker?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  appliedJobs?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  customTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  contractIdType?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  countries?: Maybe<Array<Maybe<Scalars['String']>>>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSkills?: Maybe<Array<Maybe<Scalars['String']>>>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  salaries?: Maybe<Array<Maybe<Scalars['String']>>>;
  userId?: Maybe<Scalars['String']>;
  candidateId?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  recruiterVisible?: Maybe<Scalars['Boolean']>;
  resumeName?: Maybe<Scalars['String']>;
  coverLetterName?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  targetSalary?: Maybe<TargetSalaryInput>;
  inspiringCompanies?: Maybe<Array<Maybe<InspiringCompanyInput>>>;
  source?: Maybe<Scalars['String']>;
};

export type UpdateCandidateDocument = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  personalInformation?: Maybe<Scalars['String']>;
  benchMark?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  resumeName?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  coverLetterName?: Maybe<Scalars['String']>;
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
  education?: Maybe<Array<Maybe<EducationInput>>>;
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  objectives?: Maybe<Array<Maybe<Scalars['String']>>>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  languages?: Maybe<Array<Maybe<LanguagesInput>>>;
};

export type UpdateCompany = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  confidentialdescription?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  companySize?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  totalJobs?: Maybe<Scalars['Int']>;
  facebook?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  favouritedBy?: Maybe<Scalars['String']>;
};

export type UpdateLocationExperienceAndEducation = {
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesInput>;
};

export type UpdateRecruiter = {
  fullName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
  educations?: Maybe<Array<Maybe<EducationInput>>>;
  isPublic?: Maybe<Scalars['Boolean']>;
  coverPhoto?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<LanguagesInput>>>;
  location?: Maybe<LocationFields>;
  user?: Maybe<ProfileUserInput>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  messages?: Maybe<Array<Maybe<Message>>>;
  source?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  verified?: Maybe<Scalars['Boolean']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  currentCompany?: Maybe<Scalars['String']>;
  currentJobTitle?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  /** candidate: CandidateListing */
  response?: Maybe<Response>;
  aiTokens?: Maybe<Scalars['Int']>;
  languagePreference?: Maybe<Scalars['String']>;
  userProfile?: Maybe<AiUserProfile>;
  candidate?: Maybe<CandidateListing>;
};

export type UserObj = {
  __typename?: 'UserObj';
  _id?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  email?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type UserProfileUserOutput = {
  __typename?: 'UserProfileUserOutput';
  email?: Maybe<Scalars['String']>;
};


export type YearlyCompensation = {
  __typename?: 'YearlyCompensation';
  currency?: Maybe<Scalars['String']>;
  current?: Maybe<Compensation>;
  expected?: Maybe<Compensation>;
  comments?: Maybe<Scalars['String']>;
  currentComments?: Maybe<Scalars['String']>;
};

export type YearlyCompensationInput = {
  currency?: Maybe<Scalars['String']>;
  current?: Maybe<CompensationInput>;
  expected?: Maybe<CompensationInput>;
  comments?: Maybe<Scalars['String']>;
  currentComments?: Maybe<Scalars['String']>;
};

export type AssessmentInput = {
  experience?: Maybe<Array<Maybe<AssessmentInputObj>>>;
  traits?: Maybe<Array<Maybe<AssessmentInputObj>>>;
};

export type AssessmentInputObj = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type AssessmentInputStructure = {
  __typename?: 'assessmentInputStructure';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type Blink = {
  __typename?: 'blink';
  blinkURL?: Maybe<Scalars['String']>;
  response?: Maybe<Response>;
};

export type CandidateIds = {
  candidateId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['String']>;
  matchId?: Maybe<Scalars['String']>;
};

export type CoordinatesInput = {
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type CoordinatesType = {
  __typename?: 'coordinatesType';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type CountAllCandidates = {
  __typename?: 'countAllCandidates';
  count?: Maybe<Scalars['Int']>;
};

export type CountryName = {
  __typename?: 'countryName';
  name?: Maybe<Scalars['String']>;
};

export type CrawliesOtherInfo = {
  __typename?: 'crawliesOtherInfo';
  _id?: Maybe<Scalars['ID']>;
  linkedIn?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  crawlie?: Maybe<Scalars['ID']>;
  location?: Maybe<Scalars['String']>;
};

export type CrawliesPersonalDetail = {
  __typename?: 'crawliesPersonalDetail';
  _id?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  is_candidate?: Maybe<Scalars['Boolean']>;
  CV?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type CrawliesProfessionalDetails = {
  __typename?: 'crawliesProfessionalDetails';
  _id?: Maybe<Scalars['ID']>;
  CV?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  crawlie?: Maybe<Scalars['ID']>;
};

export type CreateCandidateAndMatchResponse = {
  __typename?: 'createCandidateAndMatchResponse';
  _id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  token?: Maybe<Scalars['String']>;
  candidate?: Maybe<CandidateListingProfile>;
};

export type CreateJobLandingPageParams = {
  jobId: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  leftContent?: Maybe<Scalars['String']>;
  rightContent?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  customCss?: Maybe<Scalars['String']>;
  cssLink?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  faviconUrl?: Maybe<Scalars['String']>;
  metaImageUrl?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CreateJobParams = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  hideSalary?: Maybe<Scalars['Boolean']>;
  countries?: Maybe<Array<Maybe<Scalars['String']>>>;
  salaryPackage?: Maybe<Scalars['String']>;
  requiredSkills?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSkills?: Maybe<Array<Maybe<Scalars['String']>>>;
  experience?: Maybe<Scalars['String']>;
  currencyId?: Maybe<Scalars['String']>;
  recruiters?: Maybe<Array<Maybe<Scalars['String']>>>;
  leadRecruiter?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  contractType?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  targetCompanies?: Maybe<Array<Maybe<Scalars['String']>>>;
  AnonymousCompanyTitle?: Maybe<Scalars['String']>;
  AnonymousCompanyDescription?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  mobileBanner?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  isConfidential?: Maybe<Scalars['Boolean']>;
  assessment?: Maybe<AssessmentInput>;
  yearlyCompensation?: Maybe<YearlyCompensationInputJob>;
  progressReport?: Maybe<Scalars['String']>;
};

export type EducationInput = {
  _id?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  degree?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Boolean']>;
  endYear?: Maybe<Scalars['String']>;
  location?: Maybe<UpdateLocationExperienceAndEducation>;
  logo?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'event';
  eventName?: Maybe<Scalars['String']>;
  eventDate?: Maybe<Scalars['String']>;
  eventVenu?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  professionalInterests?: Maybe<Array<Maybe<ProfessionalIntereststype>>>;
};

export type EventInput = {
  eventName?: Maybe<Scalars['String']>;
  eventDate?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  eventVenu?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  professionalInterests?: Maybe<Array<Maybe<Scalars['String']>>>;
  facebook?: Maybe<Scalars['String']>;
};

export type ExistingUserResponse = {
  __typename?: 'existingUserResponse';
  message?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
};

export type ExperienceInput = {
  position?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
  endYear?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Scalars['String']>;
  reasonForChange?: Maybe<Scalars['String']>;
  sector?: Maybe<Array<Maybe<Scalars['String']>>>;
  jobField?: Maybe<Array<Maybe<Scalars['String']>>>;
  location?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  experienceDetails?: Maybe<Scalars['String']>;
};

export type ExperiencesInput = {
  _id?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  reasonForChange?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
  endYear?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Boolean']>;
  sector?: Maybe<Scalars['String']>;
  jobField?: Maybe<Array<Maybe<Scalars['String']>>>;
  experienceDetails?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  location?: Maybe<UpdateLocationExperienceAndEducation>;
};

export type FamousLeaders = {
  __typename?: 'famousLeaders';
  _id?: Maybe<Scalars['ID']>;
  leaderImage?: Maybe<Scalars['String']>;
  leaderName?: Maybe<Scalars['String']>;
  leaderTitle?: Maybe<Scalars['String']>;
  leaderQuestion?: Maybe<Scalars['String']>;
  videoCompleted?: Maybe<Scalars['Boolean']>;
  videoURL?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type GenerateTextResponse = {
  __typename?: 'generateTextResponse';
  remaningTokens?: Maybe<Scalars['Int']>;
  aiResponse?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type GetAllMatchData = {
  __typename?: 'getAllMatchData';
  _id: Scalars['ID'];
  job?: Maybe<Job>;
  company?: Maybe<Company>;
  recruiterAction?: Maybe<Scalars['String']>;
  candidateAction?: Maybe<Scalars['String']>;
  lastAction?: Maybe<Scalars['String']>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  bypass?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  alreadyApplied?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  candidateScore?: Maybe<Scalars['Float']>;
  matchingAlgoScores?: Maybe<Array<Maybe<MatchingAlgorithmScores>>>;
  response?: Maybe<Response>;
  country: Array<CountryName>;
};

export type GetAssessments = {
  __typename?: 'getAssessments';
  _id?: Maybe<Scalars['ID']>;
  matchId?: Maybe<Scalars['ID']>;
  hideExperience?: Maybe<Scalars['Boolean']>;
  hideTraits?: Maybe<Scalars['Boolean']>;
  hideDrivers?: Maybe<Scalars['Boolean']>;
  experience?: Maybe<Array<Maybe<AssessmentView>>>;
  traits?: Maybe<Array<Maybe<AssessmentView>>>;
  drivers?: Maybe<Array<Maybe<AssessmentView>>>;
};

export type InspiringCompany = {
  __typename?: 'inspiringCompany';
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type JobListingsModel = {
  __typename?: 'jobListingsModel';
  isConfidential?: Maybe<Scalars['Boolean']>;
};

export type Location = {
  __typename?: 'location';
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type LocationExperienceInput = {
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesInput>;
};

export type LocationInput = {
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type LocationInputRegisterationStep = {
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type LocationQuery = {
  __typename?: 'locationQuery';
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesType>;
};

export type LocationType = {
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesInput>;
};

export type LocationTypeEducationAndExperience = {
  __typename?: 'locationTypeEducationAndExperience';
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesType>;
};

export type ObjectivesOrInterests = {
  __typename?: 'objectivesORInterests';
  title?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
};

export type Objectivestype = {
  __typename?: 'objectivestype';
  _id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PersonalInterest = {
  __typename?: 'personalInterest';
  _id: Scalars['ID'];
  title: Scalars['String'];
};

export type ProfessionalIntereststype = {
  __typename?: 'professionalIntereststype';
  _id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Referral = {
  __typename?: 'referral';
  referreeId?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  jobId?: Maybe<Scalars['String']>;
  blinkURL?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  applicantReward?: Maybe<Scalars['String']>;
  reffrerReward?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
};

export type SkillsType = {
  __typename?: 'skillsType';
  _id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type TagsType = {
  __typename?: 'tagsType';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type Temp = {
  __typename?: 'temp';
  experience?: Maybe<Array<Maybe<AssessmentView>>>;
  traits?: Maybe<Array<Maybe<AssessmentView>>>;
};

export type UpdateAiUserData = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  personalInformation?: Maybe<Scalars['String']>;
  benchMark?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  resumeName?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  coverLetterName?: Maybe<Scalars['String']>;
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
  education?: Maybe<Array<Maybe<EducationInput>>>;
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  objectives?: Maybe<Array<Maybe<Scalars['String']>>>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  languages?: Maybe<Array<Maybe<LanguagesInput>>>;
  languagePreference?: Maybe<Scalars['String']>;
};

export type UserSkill = {
  __typename?: 'userSkill';
  name?: Maybe<Scalars['String']>;
};

export type YearlyCompensationInputJob = {
  currency?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  onTargetEarnings?: Maybe<Scalars['String']>;
  shortTermIncentives?: Maybe<Scalars['String']>;
  longTermIncentives?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
};

export type YearlyCompensationInputJobView = {
  __typename?: 'yearlyCompensationInputJobView';
  currency?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  onTargetEarnings?: Maybe<Scalars['String']>;
  shortTermIncentives?: Maybe<Scalars['String']>;
  longTermIncentives?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
};

export type GetMatchPopUpQueryVariables = Exact<{
  candidateId?: Maybe<Array<Maybe<CandidateIds>>>;
}>;


export type GetMatchPopUpQuery = (
  { __typename?: 'Query' }
  & { getMatchPopUp?: Maybe<Array<Maybe<(
    { __typename?: 'CandidateListingProfileForPopUp' }
    & Pick<CandidateListingProfileForPopUp, '_id' | 'firstName' | 'lastName' | 'profilePicture' | 'company' | 'position' | 'matchId'>
    & { companyData?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name' | 'logoUrl'>
    )>, jobData?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, '_id' | 'title'>
    )> }
  )>>> }
);

export type UserMessagesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserMessagesQuery = (
  { __typename?: 'Query' }
  & { messages?: Maybe<Array<Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id' | 'message' | 'senderMail' | 'receiverMail' | 'timestamp'>
    & { users?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'name' | 'email'>
    )>>> }
  )>>> }
);

export type AddCompanyMutationVariables = Exact<{
  title: Scalars['String'];
  description: Scalars['String'];
  confidentialdescription?: Maybe<Scalars['String']>;
  sector: Scalars['String'];
  companySize: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
}>;


export type AddCompanyMutation = (
  { __typename?: 'Mutation' }
  & { registerCompany?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'description' | 'confidentialdescription' | 'logoUrl' | 'banner' | 'website' | 'companySize' | 'facebook' | 'linkedIn' | 'twitter' | 'isDeleted' | 'createdAt' | 'updatedAt'>
    & { sector?: Maybe<(
      { __typename?: 'SectorType' }
      & Pick<SectorType, 'title'>
    )>, users?: Maybe<Array<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email'>
    )>>, createdBy?: Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email'>
    )> }
  )> }
);

export type AddJobMutationVariables = Exact<{
  isDraft?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  hideSalary?: Maybe<Scalars['Boolean']>;
  countries?: Maybe<Array<Maybe<Scalars['String']>>>;
  salaryPackage?: Maybe<Scalars['String']>;
  requiredSkills?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSkills?: Maybe<Array<Maybe<Scalars['String']>>>;
  experience?: Maybe<Scalars['String']>;
  currencyId?: Maybe<Scalars['String']>;
  recruiters?: Maybe<Array<Maybe<Scalars['String']>>>;
  leadRecruiter?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  contractType?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  targetCompanies?: Maybe<Array<Maybe<Scalars['String']>>>;
  jobId?: Maybe<Scalars['String']>;
  isAnonymous?: Maybe<Scalars['Boolean']>;
  AnonymousCompanyTitle?: Maybe<Scalars['String']>;
  AnonymousCompanyDescription?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  mobileBanner?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  yearlyCompensation?: Maybe<YearlyCompensationInputJob>;
  assessment?: Maybe<AssessmentInput>;
}>;


export type AddJobMutation = (
  { __typename?: 'Mutation' }
  & { createJob?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, '_id' | 'title' | 'hideSalary' | 'experience' | 'salaryPackage' | 'description' | 'createdAt' | 'status' | 'isAnonymous' | 'AnonymousCompanyTitle' | 'AnonymousCompanyDescription' | 'banner' | 'mobileBanner'>
    & { countries: Array<(
      { __typename?: 'Country' }
      & Pick<Country, '_id' | 'name' | 'code'>
    )>, requiredSkills: Array<(
      { __typename?: 'Skills' }
      & Pick<Skills, '_id' | 'title'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name' | 'logoUrl'>
      & { users?: Maybe<Array<(
        { __typename?: 'RecruiterUser' }
        & Pick<RecruiterUser, '_id' | 'email'>
      )>> }
    )>, recruiters?: Maybe<Array<Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email' | 'roles'>
    )>>>, leadRecruiter?: Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email'>
    )>, fields: Array<Maybe<(
      { __typename?: 'JobField' }
      & Pick<JobField, '_id' | 'title'>
    )>>, contractType: (
      { __typename?: 'ContractType' }
      & Pick<ContractType, '_id' | 'name'>
    ), targetCompanies: Array<(
      { __typename?: 'inspiringCompany' }
      & Pick<InspiringCompany, '_id' | 'name'>
    )>, currency?: Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, '_id' | 'name' | 'symbol'>
    )>, candidates?: Maybe<Array<(
      { __typename?: 'Match' }
      & Pick<Match, '_id'>
    )>>, yearlyCompensation?: Maybe<(
      { __typename?: 'yearlyCompensationInputJobView' }
      & Pick<YearlyCompensationInputJobView, 'currency' | 'total' | 'base' | 'onTargetEarnings' | 'shortTermIncentives' | 'longTermIncentives' | 'other' | 'comments'>
    )>, assessment?: Maybe<(
      { __typename?: 'AssessmentView' }
      & { experience?: Maybe<Array<Maybe<(
        { __typename?: 'assessmentInputStructure' }
        & Pick<AssessmentInputStructure, 'title' | 'description'>
      )>>>, traits?: Maybe<Array<Maybe<(
        { __typename?: 'assessmentInputStructure' }
        & Pick<AssessmentInputStructure, 'title' | 'description'>
      )>>> }
    )> }
  )> }
);

export type AllJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllJobsQuery = (
  { __typename?: 'Query' }
  & { AllJobs?: Maybe<Array<Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, '_id' | 'title' | 'hideSalary' | 'salaryPackage' | 'experience' | 'description' | 'createdAt' | 'status' | 'isAnonymous' | 'AnonymousCompanyTitle' | 'AnonymousCompanyDescription'>
    & { countries: Array<(
      { __typename?: 'Country' }
      & Pick<Country, '_id' | 'name' | 'code'>
    )>, requiredSkills: Array<(
      { __typename?: 'Skills' }
      & Pick<Skills, '_id' | 'title'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name' | 'logoUrl'>
      & { users?: Maybe<Array<(
        { __typename?: 'RecruiterUser' }
        & Pick<RecruiterUser, '_id' | 'email'>
      )>> }
    )>, currency?: Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, '_id' | 'name' | 'symbol'>
    )>, leadRecruiter?: Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email'>
    )>, fields: Array<Maybe<(
      { __typename?: 'JobField' }
      & Pick<JobField, '_id' | 'title'>
    )>>, contractType: (
      { __typename?: 'ContractType' }
      & Pick<ContractType, '_id' | 'name'>
    ), targetCompanies: Array<(
      { __typename?: 'inspiringCompany' }
      & Pick<InspiringCompany, '_id' | 'name'>
    )>, recruiters?: Maybe<Array<Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, 'email' | 'roles'>
    )>>>, candidates?: Maybe<Array<(
      { __typename?: 'Match' }
      & Pick<Match, '_id'>
    )>> }
  )>>> }
);

export type CompanyJobsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type CompanyJobsQuery = (
  { __typename?: 'Query' }
  & { getJobs?: Maybe<Array<Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, '_id' | 'title' | 'hideSalary' | 'salaryPackage' | 'experience' | 'description' | 'createdAt' | 'status'>
    & { countries: Array<(
      { __typename?: 'Country' }
      & Pick<Country, '_id' | 'name' | 'code'>
    )>, requiredSkills: Array<(
      { __typename?: 'Skills' }
      & Pick<Skills, '_id' | 'title'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name'>
    )>, currency?: Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, '_id' | 'name' | 'symbol'>
    )>, leadRecruiter?: Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email'>
    )>, fields: Array<Maybe<(
      { __typename?: 'JobField' }
      & Pick<JobField, '_id' | 'title'>
    )>>, contractType: (
      { __typename?: 'ContractType' }
      & Pick<ContractType, '_id' | 'name'>
    ), targetCompanies: Array<(
      { __typename?: 'inspiringCompany' }
      & Pick<InspiringCompany, '_id' | 'name'>
    )>, recruiters?: Maybe<Array<Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, 'email' | 'roles'>
    )>>>, candidates?: Maybe<Array<(
      { __typename?: 'Match' }
      & Pick<Match, '_id'>
    )>> }
  )>>> }
);

export type GetCompanyDetailFromJobQueryVariables = Exact<{
  jobId: Scalars['String'];
}>;


export type GetCompanyDetailFromJobQuery = (
  { __typename?: 'Query' }
  & { getCompanyFromJob?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name' | 'jobTitle' | '_id'>
  )> }
);

export type ContractTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ContractTypesQuery = (
  { __typename?: 'Query' }
  & { contracts: Array<(
    { __typename?: 'ContractType' }
    & Pick<ContractType, '_id' | 'name'>
  )> }
);

export type CountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesQuery = (
  { __typename?: 'Query' }
  & { countries: Array<(
    { __typename?: 'Country' }
    & Pick<Country, '_id' | 'name' | 'code'>
  )> }
);

export type CreateCountryMutationVariables = Exact<{
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type CreateCountryMutation = (
  { __typename?: 'Mutation' }
  & { createCountry?: Maybe<Array<Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, '_id' | 'name' | 'code'>
  )>>> }
);

export type CurrenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrenciesQuery = (
  { __typename?: 'Query' }
  & { currencies: Array<(
    { __typename?: 'Currency' }
    & Pick<Currency, '_id' | 'name' | 'symbol'>
  )> }
);

export type DeleteJobMutationVariables = Exact<{
  jobId?: Maybe<Scalars['String']>;
}>;


export type DeleteJobMutation = (
  { __typename?: 'Mutation' }
  & { deleteJob?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, '_id' | 'title' | 'hideSalary' | 'experience' | 'salaryPackage' | 'description' | 'createdAt' | 'status'>
    & { countries: Array<(
      { __typename?: 'Country' }
      & Pick<Country, '_id' | 'name' | 'code'>
    )>, requiredSkills: Array<(
      { __typename?: 'Skills' }
      & Pick<Skills, '_id' | 'title'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name' | 'logoUrl'>
      & { users?: Maybe<Array<(
        { __typename?: 'RecruiterUser' }
        & Pick<RecruiterUser, '_id' | 'email'>
      )>> }
    )>, recruiters?: Maybe<Array<Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email' | 'roles'>
    )>>>, leadRecruiter?: Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email'>
    )>, fields: Array<Maybe<(
      { __typename?: 'JobField' }
      & Pick<JobField, '_id' | 'title'>
    )>>, contractType: (
      { __typename?: 'ContractType' }
      & Pick<ContractType, '_id' | 'name'>
    ), targetCompanies: Array<(
      { __typename?: 'inspiringCompany' }
      & Pick<InspiringCompany, '_id' | 'name'>
    )>, currency?: Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, '_id' | 'name' | 'symbol'>
    )> }
  )> }
);

export type DisLikesAgainstAJobQueryVariables = Exact<{
  jobId: Scalars['String'];
}>;


export type DisLikesAgainstAJobQuery = (
  { __typename?: 'Query' }
  & { disLikesAgainstAJob: Array<(
    { __typename?: 'Match' }
    & Pick<Match, '_id' | 'score' | 'jobScore' | 'activationScore' | 'recruiterAction' | 'candidateAction' | 'lastAction' | 'isProfileCompleted' | 'bypass' | 'media' | 'isDeleted' | 'createdAt' | 'updatedAt'>
    & { candidateListing?: Maybe<(
      { __typename?: 'CandidateListing' }
      & Pick<CandidateListing, '_id' | 'userId' | 'firstName' | 'lastName' | 'resume' | 'position' | 'profilePicture' | 'company'>
      & { experiences?: Maybe<Array<Maybe<(
        { __typename?: 'CandidateExperience' }
        & Pick<CandidateExperience, 'position' | 'company' | 'startYear'>
      )>>>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'email'>
      )>, currentLocation?: Maybe<(
        { __typename?: 'location' }
        & Pick<Location, 'coordinates' | 'country' | 'city' | 'code'>
      )> }
    )>, jobListing?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, '_id' | 'title' | 'description'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'description' | 'logoUrl'>
    )> }
  )> }
);

export type FavouritesAgainstAJobQueryVariables = Exact<{
  jobId: Scalars['String'];
}>;


export type FavouritesAgainstAJobQuery = (
  { __typename?: 'Query' }
  & { favouritesAgainstAJob: Array<(
    { __typename?: 'Match' }
    & Pick<Match, '_id' | 'score' | 'jobScore' | 'activationScore' | 'recruiterAction' | 'candidateAction' | 'isProfileCompleted' | 'bypass' | 'media' | 'isDeleted' | 'createdAt' | 'updatedAt'>
    & { candidateListing?: Maybe<(
      { __typename?: 'CandidateListing' }
      & Pick<CandidateListing, '_id' | 'userId' | 'firstName' | 'lastName' | 'resume' | 'position' | 'profilePicture'>
      & { experiences?: Maybe<Array<Maybe<(
        { __typename?: 'CandidateExperience' }
        & Pick<CandidateExperience, 'position' | 'company' | 'startYear'>
      )>>>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'email'>
      )>, currentLocation?: Maybe<(
        { __typename?: 'location' }
        & Pick<Location, 'coordinates' | 'country' | 'city' | 'code'>
      )> }
    )>, jobListing?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, '_id' | 'title' | 'description'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'description' | 'logoUrl'>
    )> }
  )> }
);

export type GetMatchQueryVariables = Exact<{
  matchId: Scalars['String'];
}>;


export type GetMatchQuery = (
  { __typename?: 'Query' }
  & { getMatch?: Maybe<(
    { __typename?: 'Match' }
    & Pick<Match, '_id' | 'benchmark' | 'showCurrentCompensation' | 'showExpectedCompensation' | 'hidePersonalDetails' | 'nextSteps' | 'score' | 'jobScore' | 'activationScore' | 'recruiterAction' | 'lastAction' | 'candidateAction' | 'bypass' | 'media' | 'isDeleted' | 'createdAt' | 'updatedAt'>
    & { candidateListing?: Maybe<(
      { __typename?: 'CandidateListing' }
      & Pick<CandidateListing, '_id' | 'userId' | 'firstName' | 'lastName' | 'resume' | 'position' | 'description' | 'personalInformation' | 'benchMark' | 'profilePicture' | 'isProfileCompleted'>
      & { skills?: Maybe<Array<Maybe<(
        { __typename?: 'skillsType' }
        & Pick<SkillsType, '_id' | 'title'>
      )>>>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'email'>
      )>, countries?: Maybe<Array<Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'name' | 'code'>
      )>>>, currentLocation?: Maybe<(
        { __typename?: 'location' }
        & Pick<Location, 'coordinates' | 'country' | 'city' | 'code'>
      )> }
    )>, AssessmentReport?: Maybe<(
      { __typename?: 'getAssessments' }
      & Pick<GetAssessments, '_id' | 'matchId' | 'hideExperience' | 'hideTraits' | 'hideDrivers'>
      & { experience?: Maybe<Array<Maybe<(
        { __typename?: 'AssessmentView' }
        & Pick<AssessmentView, 'title' | 'level' | 'comment'>
      )>>>, traits?: Maybe<Array<Maybe<(
        { __typename?: 'AssessmentView' }
        & Pick<AssessmentView, 'title' | 'level' | 'comment'>
      )>>>, drivers?: Maybe<Array<Maybe<(
        { __typename?: 'AssessmentView' }
        & Pick<AssessmentView, 'title' | 'level' | 'comment'>
      )>>> }
    )> }
  )> }
);

export type HiredAgainstAJobQueryVariables = Exact<{
  jobId: Scalars['String'];
}>;


export type HiredAgainstAJobQuery = (
  { __typename?: 'Query' }
  & { hiredAgainstAJob: Array<(
    { __typename?: 'Match' }
    & Pick<Match, '_id' | 'benchmark' | 'score' | 'jobScore' | 'activationScore' | 'recruiterAction' | 'candidateAction' | 'isProfileCompleted' | 'bypass' | 'media' | 'isDeleted' | 'createdAt' | 'updatedAt'>
    & { candidateListing?: Maybe<(
      { __typename?: 'CandidateListing' }
      & Pick<CandidateListing, '_id' | 'userId' | 'firstName' | 'lastName' | 'resume' | 'position' | 'profilePicture' | 'company'>
      & { experiences?: Maybe<Array<Maybe<(
        { __typename?: 'CandidateExperience' }
        & Pick<CandidateExperience, 'position' | 'company' | 'startYear'>
      )>>>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'email'>
      )>, currentLocation?: Maybe<(
        { __typename?: 'location' }
        & Pick<Location, 'coordinates' | 'country' | 'city' | 'code'>
      )> }
    )>, jobListing?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, '_id' | 'title' | 'description'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'description' | 'logoUrl'>
    )> }
  )> }
);

export type InspiringCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type InspiringCompaniesQuery = (
  { __typename?: 'Query' }
  & { inspiringCompanies?: Maybe<Array<Maybe<(
    { __typename?: 'inspiringCompany' }
    & Pick<InspiringCompany, '_id' | 'name'>
  )>>> }
);

export type JobFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type JobFieldsQuery = (
  { __typename?: 'Query' }
  & { jobFields: Array<(
    { __typename?: 'JobField' }
    & Pick<JobField, '_id' | 'title'>
    & { parent?: Maybe<(
      { __typename?: 'JobField' }
      & Pick<JobField, '_id' | 'title'>
    )> }
  )> }
);

export type LikesAgainstAJobQueryVariables = Exact<{
  jobId: Scalars['String'];
}>;


export type LikesAgainstAJobQuery = (
  { __typename?: 'Query' }
  & { likesAgainstAJob: Array<(
    { __typename?: 'Match' }
    & Pick<Match, '_id' | 'score' | 'jobScore' | 'activationScore' | 'recruiterAction' | 'candidateAction' | 'isProfileCompleted' | 'bypass' | 'media' | 'isDeleted' | 'createdAt' | 'updatedAt'>
    & { candidateListing?: Maybe<(
      { __typename?: 'CandidateListing' }
      & Pick<CandidateListing, '_id' | 'userId' | 'firstName' | 'lastName' | 'resume' | 'position' | 'profilePicture'>
      & { experiences?: Maybe<Array<Maybe<(
        { __typename?: 'CandidateExperience' }
        & Pick<CandidateExperience, 'position' | 'company' | 'startYear'>
      )>>>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'email'>
      )>, currentLocation?: Maybe<(
        { __typename?: 'location' }
        & Pick<Location, 'coordinates' | 'country' | 'city' | 'code'>
      )> }
    )>, jobListing?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, '_id' | 'title' | 'description'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'description' | 'logoUrl'>
    )> }
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { recruiterLogin?: Maybe<(
    { __typename?: 'RecruiterUser' }
    & Pick<RecruiterUser, '_id' | 'email' | 'userId' | 'fullName' | 'title' | 'roles' | 'verified' | 'subscriptionPlanId' | 'token'>
  )> }
);

export type MatchesAgainstAJobQueryVariables = Exact<{
  jobId: Scalars['String'];
}>;


export type MatchesAgainstAJobQuery = (
  { __typename?: 'Query' }
  & { matchesAgainstAJob: Array<(
    { __typename?: 'Match' }
    & Pick<Match, '_id' | 'benchmark' | 'score' | 'jobScore' | 'activationScore' | 'recruiterAction' | 'candidateAction' | 'isProfileCompleted' | 'bypass' | 'media' | 'isDeleted' | 'createdAt' | 'updatedAt'>
    & { candidateListing?: Maybe<(
      { __typename?: 'CandidateListing' }
      & Pick<CandidateListing, '_id' | 'userId' | 'firstName' | 'lastName' | 'resume' | 'position' | 'profilePicture' | 'company'>
      & { experiences?: Maybe<Array<Maybe<(
        { __typename?: 'CandidateExperience' }
        & Pick<CandidateExperience, 'position' | 'company' | 'startYear'>
      )>>>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'email'>
      )>, currentLocation?: Maybe<(
        { __typename?: 'location' }
        & Pick<Location, 'coordinates' | 'country' | 'city' | 'code'>
      )> }
    )>, jobListing?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, '_id' | 'title' | 'description'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'description' | 'logoUrl'>
    )> }
  )> }
);

export type MatchesForAJobQueryVariables = Exact<{
  jobId: Scalars['String'];
  searchParams?: Maybe<SearchParamsInput>;
}>;


export type MatchesForAJobQuery = (
  { __typename?: 'Query' }
  & { matchesForAJob: Array<(
    { __typename?: 'Match' }
    & Pick<Match, '_id' | 'score' | 'jobScore' | 'activationScore' | 'recruiterAction' | 'candidateAction' | 'bypass' | 'media' | 'isDeleted' | 'createdAt' | 'updatedAt'>
    & { jobListing?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, '_id' | 'title' | 'description'>
    )>, candidateListing?: Maybe<(
      { __typename?: 'CandidateListing' }
      & Pick<CandidateListing, '_id' | 'userId' | 'firstName' | 'lastName' | 'resume' | 'position' | 'isProfileCompleted'>
      & { experiences?: Maybe<Array<Maybe<(
        { __typename?: 'CandidateExperience' }
        & Pick<CandidateExperience, 'position' | 'company' | 'startYear'>
      )>>>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'email'>
      )>, countries?: Maybe<Array<Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'name' | 'code'>
      )>>>, location?: Maybe<Array<Maybe<(
        { __typename?: 'location' }
        & Pick<Location, 'coordinates' | 'country' | 'city' | 'code'>
      )>>> }
    )>, matchingAlgoScores?: Maybe<Array<Maybe<(
      { __typename?: 'MatchingAlgorithmScores' }
      & Pick<MatchingAlgorithmScores, 'candidateScore' | 'jobScore'>
    )>>> }
  )> }
);

export type NewCandidatesAgainstAJobQueryVariables = Exact<{
  jobId: Scalars['String'];
}>;


export type NewCandidatesAgainstAJobQuery = (
  { __typename?: 'Query' }
  & { newCandidatesAgainstAJob: Array<(
    { __typename?: 'Match' }
    & Pick<Match, '_id' | 'benchmark' | 'score' | 'jobScore' | 'activationScore' | 'recruiterAction' | 'candidateAction' | 'isProfileCompleted' | 'bypass' | 'media' | 'isDeleted' | 'createdAt' | 'updatedAt'>
    & { candidateListing?: Maybe<(
      { __typename?: 'CandidateListing' }
      & Pick<CandidateListing, '_id' | 'userId' | 'firstName' | 'lastName' | 'resume' | 'profilePicture' | 'position' | 'company'>
      & { experiences?: Maybe<Array<Maybe<(
        { __typename?: 'CandidateExperience' }
        & Pick<CandidateExperience, 'position' | 'company' | 'startYear'>
      )>>>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'email'>
      )>, currentLocation?: Maybe<(
        { __typename?: 'location' }
        & Pick<Location, 'coordinates' | 'country' | 'city' | 'code'>
      )> }
    )>, jobListing?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, '_id' | 'title' | 'description'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'description' | 'logoUrl'>
    )> }
  )> }
);

export type RecruiterActionMutationVariables = Exact<{
  matchId: Scalars['String'];
  action: Scalars['String'];
  from?: Maybe<Scalars['String']>;
  lastAction?: Maybe<Scalars['String']>;
}>;


export type RecruiterActionMutation = (
  { __typename?: 'Mutation' }
  & { recruiterAction?: Maybe<(
    { __typename?: 'Match' }
    & Pick<Match, '_id' | 'score' | 'jobScore' | 'activationScore' | 'recruiterAction' | 'candidateAction' | 'lastAction' | 'isProfileCompleted' | 'bypass' | 'media' | 'isDeleted' | 'createdAt' | 'updatedAt'>
    & { candidateListing?: Maybe<(
      { __typename?: 'CandidateListing' }
      & Pick<CandidateListing, '_id' | 'userId' | 'firstName' | 'lastName' | 'resume' | 'position'>
      & { experiences?: Maybe<Array<Maybe<(
        { __typename?: 'CandidateExperience' }
        & Pick<CandidateExperience, 'position' | 'company' | 'startYear'>
      )>>>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'email'>
      )>, currentLocation?: Maybe<(
        { __typename?: 'location' }
        & Pick<Location, 'coordinates' | 'country' | 'city' | 'code'>
      )> }
    )> }
  )> }
);

export type SendMessageMutationVariables = Exact<{
  matchId: Scalars['String'];
  action: Scalars['String'];
  message?: Maybe<Scalars['String']>;
}>;


export type SendMessageMutation = (
  { __typename?: 'Mutation' }
  & { messageTriggerMailRecruiter?: Maybe<(
    { __typename?: 'Response' }
    & Pick<Response, 'message'>
  )> }
);

export type SendMessageToCandidateMutationVariables = Exact<{
  sendTo: Scalars['String'];
  myRole: Scalars['String'];
  message: Scalars['String'];
  payload?: Maybe<MessagePayload>;
}>;


export type SendMessageToCandidateMutation = (
  { __typename?: 'Mutation' }
  & { sendMessage?: Maybe<(
    { __typename?: 'Response' }
    & Pick<Response, 'message' | 'status'>
  )> }
);

export type RejectedAgainstAJobQueryVariables = Exact<{
  jobId: Scalars['String'];
}>;


export type RejectedAgainstAJobQuery = (
  { __typename?: 'Query' }
  & { rejectedAgainstAJob: Array<(
    { __typename?: 'Match' }
    & Pick<Match, '_id' | 'score' | 'jobScore' | 'activationScore' | 'recruiterAction' | 'candidateAction' | 'isProfileCompleted' | 'bypass' | 'media' | 'isDeleted' | 'createdAt' | 'updatedAt'>
    & { candidateListing?: Maybe<(
      { __typename?: 'CandidateListing' }
      & Pick<CandidateListing, '_id' | 'userId' | 'firstName' | 'lastName' | 'resume' | 'position' | 'profilePicture' | 'company'>
      & { experiences?: Maybe<Array<Maybe<(
        { __typename?: 'CandidateExperience' }
        & Pick<CandidateExperience, 'position' | 'company' | 'startYear'>
      )>>>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'email'>
      )>, currentLocation?: Maybe<(
        { __typename?: 'location' }
        & Pick<Location, 'coordinates' | 'country' | 'city' | 'code'>
      )> }
    )>, jobListing?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, '_id' | 'title' | 'description'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'description' | 'logoUrl'>
    )> }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { recruiterResetPassword?: Maybe<(
    { __typename?: 'RecruiterUser' }
    & { response?: Maybe<(
      { __typename?: 'Response' }
      & Pick<Response, 'message'>
    )> }
  )> }
);

export type ResetPasswordRequestMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordRequestMutation = (
  { __typename?: 'Mutation' }
  & { sendPasswordResetEmail?: Maybe<(
    { __typename?: 'Response' }
    & Pick<Response, 'message'>
  )> }
);

export type SectorsQueryVariables = Exact<{ [key: string]: never; }>;


export type SectorsQuery = (
  { __typename?: 'Query' }
  & { sectors?: Maybe<Array<Maybe<(
    { __typename?: 'SectorType' }
    & Pick<SectorType, '_id' | 'title'>
  )>>> }
);

export type SelectedAgainstAJobQueryVariables = Exact<{
  jobId: Scalars['String'];
}>;


export type SelectedAgainstAJobQuery = (
  { __typename?: 'Query' }
  & { selectedAgainstAJob: Array<(
    { __typename?: 'Match' }
    & Pick<Match, '_id' | 'benchmark' | 'score' | 'jobScore' | 'activationScore' | 'recruiterAction' | 'candidateAction' | 'isProfileCompleted' | 'bypass' | 'media' | 'isDeleted' | 'createdAt' | 'updatedAt'>
    & { candidateListing?: Maybe<(
      { __typename?: 'CandidateListing' }
      & Pick<CandidateListing, '_id' | 'userId' | 'firstName' | 'lastName' | 'resume' | 'position' | 'profilePicture' | 'company'>
      & { experiences?: Maybe<Array<Maybe<(
        { __typename?: 'CandidateExperience' }
        & Pick<CandidateExperience, 'position' | 'company' | 'startYear'>
      )>>>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'email'>
      )>, currentLocation?: Maybe<(
        { __typename?: 'location' }
        & Pick<Location, 'coordinates' | 'country' | 'city' | 'code'>
      )> }
    )>, jobListing?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, '_id' | 'title' | 'description'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'name' | 'description' | 'logoUrl'>
    )> }
  )> }
);

export type RecruiterSignupMutationVariables = Exact<{
  fullName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  title: Scalars['String'];
  company: Scalars['String'];
  location: UpdateLocationExperienceAndEducation;
}>;


export type RecruiterSignupMutation = (
  { __typename?: 'Mutation' }
  & { recruiterSignup?: Maybe<(
    { __typename?: 'RecruiterUser' }
    & Pick<RecruiterUser, '_id' | 'userId' | 'title' | 'subscriptionPlanId' | 'fullName' | 'email' | 'token'>
  )> }
);

export type VerifyRecruiterSignupMutationVariables = Exact<{
  userId: Scalars['String'];
  code: Scalars['String'];
}>;


export type VerifyRecruiterSignupMutation = (
  { __typename?: 'Mutation' }
  & { VerifyRecruiterSignup?: Maybe<(
    { __typename?: 'Response' }
    & Pick<Response, 'message'>
  )> }
);

export type GetCandidateQueryVariables = Exact<{
  candidateId: Scalars['String'];
}>;


export type GetCandidateQuery = (
  { __typename?: 'Query' }
  & { getCandidate?: Maybe<(
    { __typename?: 'CandidateListingProfile' }
    & Pick<CandidateListingProfile, '_id' | 'firstName' | 'lastName' | 'position' | 'description' | 'personalInformation' | 'facebook' | 'github' | 'linkedIn' | 'twitter' | 'benchMark' | 'totalExperience' | 'coverPhoto' | 'educationComment' | 'resume' | 'coverLetter' | 'isPublic' | 'profilePicture' | 'profileVideo' | 'targetRoles' | 'desiredSalaryPackage' | 'currentSalaryPackage' | 'isOnlyNetworker' | 'isDeleted' | 'token' | 'subscriptionPlanId' | 'createdAt' | 'updatedAt' | 'appliedJobs' | 'contractIdType' | 'company' | 'userId' | 'isProfileCompleted' | 'languageComments' | 'nextSteps'>
    & { experiences?: Maybe<Array<Maybe<(
      { __typename?: 'CandidateExperience' }
      & Pick<CandidateExperience, 'position' | 'company' | 'startYear' | 'endYear' | 'current' | 'jobField' | 'reasonForChange' | 'comments' | 'logo'>
      & { commentData?: Maybe<(
        { __typename?: 'ExperienceCommentData' }
        & Pick<ExperienceCommentData, 'candidateId' | 'company' | 'comments' | 'reasonForChange'>
      )>, location?: Maybe<(
        { __typename?: 'locationTypeEducationAndExperience' }
        & Pick<LocationTypeEducationAndExperience, 'country' | 'city' | 'code'>
        & { coordinates?: Maybe<(
          { __typename?: 'coordinatesType' }
          & Pick<CoordinatesType, 'lat' | 'lng'>
        )> }
      )> }
    )>>>, education?: Maybe<Array<Maybe<(
      { __typename?: 'CandidateEducation' }
      & Pick<CandidateEducation, 'schoolName' | 'degree' | 'startYear' | 'current' | 'endYear' | 'logo'>
      & { location?: Maybe<(
        { __typename?: 'locationTypeEducationAndExperience' }
        & Pick<LocationTypeEducationAndExperience, 'country' | 'city' | 'code'>
        & { coordinates?: Maybe<(
          { __typename?: 'coordinatesType' }
          & Pick<CoordinatesType, 'lat' | 'lng'>
        )> }
      )> }
    )>>>, inspiringCompanies?: Maybe<Array<Maybe<(
      { __typename?: 'InspiringCompanyType' }
      & Pick<InspiringCompanyType, '_id' | 'name'>
    )>>>, currentLocation?: Maybe<(
      { __typename?: 'location' }
      & Pick<Location, 'country' | 'city' | 'code' | 'coordinates'>
    )>, objectives?: Maybe<Array<Maybe<(
      { __typename?: 'objectivestype' }
      & Pick<Objectivestype, '_id' | 'title'>
    )>>>, professionalInterests?: Maybe<Array<Maybe<(
      { __typename?: 'professionalIntereststype' }
      & Pick<ProfessionalIntereststype, '_id' | 'title'>
    )>>>, tags?: Maybe<Array<Maybe<(
      { __typename?: 'tagsType' }
      & Pick<TagsType, '_id' | 'name' | 'color'>
    )>>>, sectors?: Maybe<Array<Maybe<(
      { __typename?: 'SectorsType' }
      & Pick<SectorsType, '_id' | 'title'>
    )>>>, skills?: Maybe<Array<Maybe<(
      { __typename?: 'skillsType' }
      & Pick<SkillsType, '_id' | 'title'>
    )>>>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'email'>
    )>, targetLocations?: Maybe<Array<Maybe<(
      { __typename?: 'CandidateTargetLocation' }
      & Pick<CandidateTargetLocation, '_id' | 'name' | 'city'>
    )>>>, languages?: Maybe<Array<Maybe<(
      { __typename?: 'Languages' }
      & Pick<Languages, 'language' | 'level'>
    )>>>, yearlyCompensation?: Maybe<(
      { __typename?: 'YearlyCompensation' }
      & Pick<YearlyCompensation, 'comments' | 'currentComments' | 'currency'>
      & { current?: Maybe<(
        { __typename?: 'Compensation' }
        & Pick<Compensation, 'base' | 'onTargetEarnings' | 'shortTermIncentives' | 'longTermIncentives' | 'other' | 'total'>
      )>, expected?: Maybe<(
        { __typename?: 'Compensation' }
        & Pick<Compensation, 'base' | 'onTargetEarnings' | 'shortTermIncentives' | 'longTermIncentives' | 'other' | 'total'>
      )> }
    )> }
  )> }
);

export type GetSingleCompanyQueryVariables = Exact<{
  companyId: Scalars['String'];
}>;


export type GetSingleCompanyQuery = (
  { __typename?: 'Query' }
  & { getSingleCompany?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'description' | 'website' | 'companySize' | 'logoUrl' | 'banner' | 'createdAt' | 'facebook' | 'linkedIn' | 'twitter' | 'totalJobs'>
    & { sector?: Maybe<(
      { __typename?: 'SectorType' }
      & Pick<SectorType, 'title'>
    )>, createdBy?: Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email'>
    )>, users?: Maybe<Array<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email'>
    )>>, jobs?: Maybe<Array<(
      { __typename?: 'Job' }
      & Pick<Job, '_id' | 'title'>
    )>> }
  )> }
);

export type GetSingleJobQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type GetSingleJobQuery = (
  { __typename?: 'Query' }
  & { getJobDetails?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, '_id' | 'title' | 'hideSalary' | 'experience' | 'salaryPackage' | 'description' | 'createdAt' | 'status' | 'isAnonymous' | 'AnonymousCompanyTitle' | 'AnonymousCompanyDescription' | 'banner' | 'mobileBanner' | 'language' | 'progressReport'>
    & { countries: Array<(
      { __typename?: 'Country' }
      & Pick<Country, '_id' | 'name' | 'code'>
    )>, requiredSkills: Array<(
      { __typename?: 'Skills' }
      & Pick<Skills, '_id' | 'title'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name' | 'logoUrl'>
      & { users?: Maybe<Array<(
        { __typename?: 'RecruiterUser' }
        & Pick<RecruiterUser, '_id' | 'email'>
      )>> }
    )>, recruiters?: Maybe<Array<Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email' | 'roles'>
    )>>>, leadRecruiter?: Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email'>
    )>, fields: Array<Maybe<(
      { __typename?: 'JobField' }
      & Pick<JobField, '_id' | 'title'>
    )>>, contractType: (
      { __typename?: 'ContractType' }
      & Pick<ContractType, '_id' | 'name'>
    ), targetCompanies: Array<(
      { __typename?: 'inspiringCompany' }
      & Pick<InspiringCompany, '_id' | 'name'>
    )>, currency?: Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, '_id' | 'name' | 'symbol'>
    )>, candidates?: Maybe<Array<(
      { __typename?: 'Match' }
      & Pick<Match, '_id'>
    )>>, yearlyCompensation?: Maybe<(
      { __typename?: 'yearlyCompensationInputJobView' }
      & Pick<YearlyCompensationInputJobView, 'currency' | 'total' | 'base' | 'onTargetEarnings' | 'shortTermIncentives' | 'longTermIncentives' | 'other' | 'comments'>
    )>, assessment?: Maybe<(
      { __typename?: 'AssessmentView' }
      & { experience?: Maybe<Array<Maybe<(
        { __typename?: 'assessmentInputStructure' }
        & Pick<AssessmentInputStructure, 'title' | 'description'>
      )>>>, traits?: Maybe<Array<Maybe<(
        { __typename?: 'assessmentInputStructure' }
        & Pick<AssessmentInputStructure, 'title' | 'description'>
      )>>> }
    )> }
  )> }
);

export type GetSingleRecruiterQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSingleRecruiterQuery = (
  { __typename?: 'Query' }
  & { getSingleRecruiter?: Maybe<(
    { __typename?: 'RecruiterBOUser' }
    & Pick<RecruiterBoUser, '_id' | 'fullName' | 'profilePic' | 'profileVideo' | 'title' | 'createdAt' | 'company' | 'facebook' | 'twitter' | 'github' | 'linkedIn' | 'coverPhoto' | 'description'>
    & { location?: Maybe<(
      { __typename?: 'locationTypeEducationAndExperience' }
      & Pick<LocationTypeEducationAndExperience, 'city' | 'country'>
    )>, user?: Maybe<(
      { __typename?: 'UserObj' }
      & Pick<UserObj, 'email'>
    )>, educations?: Maybe<Array<Maybe<(
      { __typename?: 'RecruiterEducation' }
      & Pick<RecruiterEducation, '_id' | 'schoolName' | 'degree' | 'startYear' | 'current' | 'endYear' | 'logo'>
      & { location?: Maybe<(
        { __typename?: 'locationTypeEducationAndExperience' }
        & Pick<LocationTypeEducationAndExperience, 'city' | 'country'>
      )> }
    )>>>, experiences?: Maybe<Array<Maybe<(
      { __typename?: 'RecruiterExperience' }
      & Pick<RecruiterExperience, '_id' | 'position' | 'company' | 'startYear' | 'endYear' | 'current' | 'sector' | 'jobField' | 'logo'>
      & { location?: Maybe<(
        { __typename?: 'locationTypeEducationAndExperience' }
        & Pick<LocationTypeEducationAndExperience, 'city' | 'country'>
      )> }
    )>>> }
  )> }
);

export type SkillsQueryVariables = Exact<{
  status?: Maybe<Scalars['String']>;
}>;


export type SkillsQuery = (
  { __typename?: 'Query' }
  & { skills: Array<(
    { __typename?: 'Skills' }
    & Pick<Skills, '_id' | 'title' | 'isArchived' | 'isDeleted' | 'createdBy'>
  )> }
);

export type UpdateJobIsFavouriteMutationVariables = Exact<{
  jobId?: Maybe<Scalars['String']>;
  isFavourite: Scalars['Boolean'];
}>;


export type UpdateJobIsFavouriteMutation = (
  { __typename?: 'Mutation' }
  & { updateJobIsFavourite?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, '_id' | 'title' | 'hideSalary' | 'experience' | 'salaryPackage' | 'description' | 'createdAt' | 'status' | 'favouriteBy'>
    & { countries: Array<(
      { __typename?: 'Country' }
      & Pick<Country, '_id' | 'name' | 'code'>
    )>, requiredSkills: Array<(
      { __typename?: 'Skills' }
      & Pick<Skills, '_id' | 'title'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name' | 'logoUrl'>
      & { users?: Maybe<Array<(
        { __typename?: 'RecruiterUser' }
        & Pick<RecruiterUser, '_id' | 'email'>
      )>> }
    )>, recruiters?: Maybe<Array<Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email' | 'roles'>
    )>>>, leadRecruiter?: Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email'>
    )>, fields: Array<Maybe<(
      { __typename?: 'JobField' }
      & Pick<JobField, '_id' | 'title'>
    )>>, contractType: (
      { __typename?: 'ContractType' }
      & Pick<ContractType, '_id' | 'name'>
    ), targetCompanies: Array<(
      { __typename?: 'inspiringCompany' }
      & Pick<InspiringCompany, '_id' | 'name'>
    )>, currency?: Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, '_id' | 'name' | 'symbol'>
    )> }
  )> }
);

export type UpdateJobStatusMutationVariables = Exact<{
  jobId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
}>;


export type UpdateJobStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateJobStatus?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, '_id' | 'title' | 'hideSalary' | 'experience' | 'salaryPackage' | 'description' | 'createdAt' | 'status'>
    & { countries: Array<(
      { __typename?: 'Country' }
      & Pick<Country, '_id' | 'name' | 'code'>
    )>, requiredSkills: Array<(
      { __typename?: 'Skills' }
      & Pick<Skills, '_id' | 'title'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name' | 'logoUrl'>
      & { users?: Maybe<Array<(
        { __typename?: 'RecruiterUser' }
        & Pick<RecruiterUser, '_id' | 'email'>
      )>> }
    )>, recruiters?: Maybe<Array<Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email' | 'roles'>
    )>>>, leadRecruiter?: Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email'>
    )>, fields: Array<Maybe<(
      { __typename?: 'JobField' }
      & Pick<JobField, '_id' | 'title'>
    )>>, contractType: (
      { __typename?: 'ContractType' }
      & Pick<ContractType, '_id' | 'name'>
    ), targetCompanies: Array<(
      { __typename?: 'inspiringCompany' }
      & Pick<InspiringCompany, '_id' | 'name'>
    )>, currency?: Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, '_id' | 'name' | 'symbol'>
    )> }
  )> }
);

export type UpdateRecruiterMutationVariables = Exact<{
  recruiterId: Scalars['String'];
  recruiter: UpdateRecruiter;
}>;


export type UpdateRecruiterMutation = (
  { __typename?: 'Mutation' }
  & { updateRecruiter?: Maybe<(
    { __typename?: 'RecruiterUser' }
    & Pick<RecruiterUser, '_id' | 'fullName' | 'profilePic' | 'profileVideo' | 'roles' | 'email' | 'title' | 'verified' | 'token' | 'company' | 'facebook' | 'twitter' | 'linkedIn' | 'github' | 'description'>
    & { user?: Maybe<(
      { __typename?: 'UserProfileUserOutput' }
      & Pick<UserProfileUserOutput, 'email'>
    )>, location?: Maybe<(
      { __typename?: 'locationQuery' }
      & Pick<LocationQuery, 'country' | 'city'>
    )> }
  )> }
);

export type UserAllJobsQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
}>;


export type UserAllJobsQuery = (
  { __typename?: 'Query' }
  & { getUserJobs?: Maybe<Array<Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, '_id' | 'title' | 'hideSalary' | 'favouriteBy' | 'totalJobs' | 'salaryPackage' | 'experience' | 'isDraft' | 'createdAt' | 'status' | 'banner' | 'mobileBanner'>
    & { countries: Array<(
      { __typename?: 'Country' }
      & Pick<Country, '_id' | 'name' | 'code'>
    )>, requiredSkills: Array<(
      { __typename?: 'Skills' }
      & Pick<Skills, '_id' | 'title'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name' | 'logoUrl'>
      & { users?: Maybe<Array<(
        { __typename?: 'RecruiterUser' }
        & Pick<RecruiterUser, '_id' | 'email'>
      )>> }
    )>, currency?: Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, '_id' | 'name' | 'symbol'>
    )>, leadRecruiter?: Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email'>
    )>, fields: Array<Maybe<(
      { __typename?: 'JobField' }
      & Pick<JobField, '_id' | 'title'>
    )>>, contractType: (
      { __typename?: 'ContractType' }
      & Pick<ContractType, '_id' | 'name'>
    ), owner?: Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'userId' | 'fullName' | 'title'>
    )>, targetCompanies: Array<(
      { __typename?: 'inspiringCompany' }
      & Pick<InspiringCompany, '_id' | 'name'>
    )>, recruiters?: Maybe<Array<Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, 'email' | 'roles' | 'fullName' | 'profilePic'>
    )>>>, recruitersDetail?: Maybe<Array<Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, 'fullName' | 'title' | 'profilePic'>
    )>>>, candidates?: Maybe<Array<(
      { __typename?: 'Match' }
      & Pick<Match, '_id' | 'recruiterAction'>
    )>> }
  )>>> }
);

export type CompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesQuery = (
  { __typename?: 'Query' }
  & { userCompanies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'description' | 'logoUrl' | 'banner' | 'website' | 'companySize' | 'totalJobs' | 'facebook' | 'linkedIn' | 'twitter' | 'isDeleted' | 'createdAt' | 'updatedAt'>
    & { sector?: Maybe<(
      { __typename?: 'SectorType' }
      & Pick<SectorType, 'title'>
    )>, users?: Maybe<Array<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email' | 'roles'>
    )>>, jobs?: Maybe<Array<(
      { __typename?: 'Job' }
      & Pick<Job, '_id' | 'title'>
    )>>, createdBy?: Maybe<(
      { __typename?: 'RecruiterUser' }
      & Pick<RecruiterUser, '_id' | 'email'>
    )> }
  )> }
);


export const GetMatchPopUpDocument = gql`
    query getMatchPopUp($candidateId: [candidateIds]) {
  getMatchPopUp(candidateId: $candidateId) {
    _id
    firstName
    lastName
    profilePicture
    company
    position
    matchId
    companyData {
      _id
      name
      logoUrl
    }
    jobData {
      _id
      title
    }
  }
}
    `;

/**
 * __useGetMatchPopUpQuery__
 *
 * To run a query within a React component, call `useGetMatchPopUpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchPopUpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchPopUpQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useGetMatchPopUpQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMatchPopUpQuery, GetMatchPopUpQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMatchPopUpQuery, GetMatchPopUpQueryVariables>(GetMatchPopUpDocument, baseOptions);
      }
export function useGetMatchPopUpLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMatchPopUpQuery, GetMatchPopUpQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMatchPopUpQuery, GetMatchPopUpQueryVariables>(GetMatchPopUpDocument, baseOptions);
        }
export type GetMatchPopUpQueryHookResult = ReturnType<typeof useGetMatchPopUpQuery>;
export type GetMatchPopUpLazyQueryHookResult = ReturnType<typeof useGetMatchPopUpLazyQuery>;
export type GetMatchPopUpQueryResult = ApolloReactCommon.QueryResult<GetMatchPopUpQuery, GetMatchPopUpQueryVariables>;
export const UserMessagesDocument = gql`
    query userMessages {
  messages {
    id
    message
    senderMail
    receiverMail
    timestamp
    users {
      name
      email
    }
  }
}
    `;

/**
 * __useUserMessagesQuery__
 *
 * To run a query within a React component, call `useUserMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserMessagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserMessagesQuery, UserMessagesQueryVariables>) {
        return ApolloReactHooks.useQuery<UserMessagesQuery, UserMessagesQueryVariables>(UserMessagesDocument, baseOptions);
      }
export function useUserMessagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserMessagesQuery, UserMessagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserMessagesQuery, UserMessagesQueryVariables>(UserMessagesDocument, baseOptions);
        }
export type UserMessagesQueryHookResult = ReturnType<typeof useUserMessagesQuery>;
export type UserMessagesLazyQueryHookResult = ReturnType<typeof useUserMessagesLazyQuery>;
export type UserMessagesQueryResult = ApolloReactCommon.QueryResult<UserMessagesQuery, UserMessagesQueryVariables>;
export const AddCompanyDocument = gql`
    mutation addCompany($title: String!, $description: String!, $confidentialdescription: String, $sector: String!, $companySize: String!, $logoUrl: String, $website: String, $facebook: String, $linkedIn: String, $twitter: String, $companyId: String, $banner: String) {
  registerCompany(
    companyData: {name: $title, description: $description, confidentialdescription: $confidentialdescription, logoUrl: $logoUrl, sector: $sector, website: $website, companySize: $companySize, facebook: $facebook, twitter: $twitter, linkedIn: $linkedIn, banner: $banner}
    companyId: $companyId
  ) {
    _id
    name
    description
    confidentialdescription
    logoUrl
    banner
    website
    sector {
      title
    }
    companySize
    users {
      _id
      email
    }
    facebook
    linkedIn
    twitter
    isDeleted
    createdBy {
      _id
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export type AddCompanyMutationFn = ApolloReactCommon.MutationFunction<AddCompanyMutation, AddCompanyMutationVariables>;

/**
 * __useAddCompanyMutation__
 *
 * To run a mutation, you first call `useAddCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyMutation, { data, loading, error }] = useAddCompanyMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      confidentialdescription: // value for 'confidentialdescription'
 *      sector: // value for 'sector'
 *      companySize: // value for 'companySize'
 *      logoUrl: // value for 'logoUrl'
 *      website: // value for 'website'
 *      facebook: // value for 'facebook'
 *      linkedIn: // value for 'linkedIn'
 *      twitter: // value for 'twitter'
 *      companyId: // value for 'companyId'
 *      banner: // value for 'banner'
 *   },
 * });
 */
export function useAddCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCompanyMutation, AddCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCompanyMutation, AddCompanyMutationVariables>(AddCompanyDocument, baseOptions);
      }
export type AddCompanyMutationHookResult = ReturnType<typeof useAddCompanyMutation>;
export type AddCompanyMutationResult = ApolloReactCommon.MutationResult<AddCompanyMutation>;
export type AddCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCompanyMutation, AddCompanyMutationVariables>;
export const AddJobDocument = gql`
    mutation addJob($isDraft: Boolean, $title: String!, $description: String, $hideSalary: Boolean, $countries: [String], $salaryPackage: String, $requiredSkills: [String], $customSkills: [String], $experience: String, $currencyId: String, $recruiters: [String], $leadRecruiter: String, $fields: [String], $contractType: String, $companyId: String, $targetCompanies: [String], $jobId: String, $isAnonymous: Boolean, $AnonymousCompanyTitle: String, $AnonymousCompanyDescription: String, $banner: String, $mobileBanner: String, $language: String, $yearlyCompensation: yearlyCompensationInputJob, $assessment: assessmentInput) {
  createJob(
    job: {title: $title, description: $description, hideSalary: $hideSalary, countries: $countries, salaryPackage: $salaryPackage, requiredSkills: $requiredSkills, customSkills: $customSkills, experience: $experience, companyId: $companyId, recruiters: $recruiters, leadRecruiter: $leadRecruiter, fields: $fields, contractType: $contractType, currencyId: $currencyId, targetCompanies: $targetCompanies, AnonymousCompanyTitle: $AnonymousCompanyTitle, AnonymousCompanyDescription: $AnonymousCompanyDescription, banner: $banner, mobileBanner: $mobileBanner, language: $language, yearlyCompensation: $yearlyCompensation, assessment: $assessment}
    jobId: $jobId
    isAnonymous: $isAnonymous
    isDraft: $isDraft
  ) {
    _id
    title
    hideSalary
    countries {
      _id
      name
      code
    }
    experience
    salaryPackage
    description
    requiredSkills {
      _id
      title
    }
    company {
      _id
      name
      logoUrl
      users {
        _id
        email
      }
    }
    recruiters {
      _id
      email
      roles
    }
    leadRecruiter {
      _id
      email
    }
    fields {
      _id
      title
    }
    contractType {
      _id
      name
    }
    createdAt
    targetCompanies {
      _id
      name
    }
    currency {
      _id
      name
      symbol
    }
    status
    isAnonymous
    AnonymousCompanyTitle
    AnonymousCompanyDescription
    candidates {
      _id
    }
    banner
    mobileBanner
    yearlyCompensation {
      currency
      total
      base
      onTargetEarnings
      shortTermIncentives
      longTermIncentives
      other
      comments
    }
    assessment {
      experience {
        title
        description
      }
      traits {
        title
        description
      }
    }
  }
}
    `;
export type AddJobMutationFn = ApolloReactCommon.MutationFunction<AddJobMutation, AddJobMutationVariables>;

/**
 * __useAddJobMutation__
 *
 * To run a mutation, you first call `useAddJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addJobMutation, { data, loading, error }] = useAddJobMutation({
 *   variables: {
 *      isDraft: // value for 'isDraft'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      hideSalary: // value for 'hideSalary'
 *      countries: // value for 'countries'
 *      salaryPackage: // value for 'salaryPackage'
 *      requiredSkills: // value for 'requiredSkills'
 *      customSkills: // value for 'customSkills'
 *      experience: // value for 'experience'
 *      currencyId: // value for 'currencyId'
 *      recruiters: // value for 'recruiters'
 *      leadRecruiter: // value for 'leadRecruiter'
 *      fields: // value for 'fields'
 *      contractType: // value for 'contractType'
 *      companyId: // value for 'companyId'
 *      targetCompanies: // value for 'targetCompanies'
 *      jobId: // value for 'jobId'
 *      isAnonymous: // value for 'isAnonymous'
 *      AnonymousCompanyTitle: // value for 'AnonymousCompanyTitle'
 *      AnonymousCompanyDescription: // value for 'AnonymousCompanyDescription'
 *      banner: // value for 'banner'
 *      mobileBanner: // value for 'mobileBanner'
 *      language: // value for 'language'
 *      yearlyCompensation: // value for 'yearlyCompensation'
 *      assessment: // value for 'assessment'
 *   },
 * });
 */
export function useAddJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddJobMutation, AddJobMutationVariables>) {
        return ApolloReactHooks.useMutation<AddJobMutation, AddJobMutationVariables>(AddJobDocument, baseOptions);
      }
export type AddJobMutationHookResult = ReturnType<typeof useAddJobMutation>;
export type AddJobMutationResult = ApolloReactCommon.MutationResult<AddJobMutation>;
export type AddJobMutationOptions = ApolloReactCommon.BaseMutationOptions<AddJobMutation, AddJobMutationVariables>;
export const AllJobsDocument = gql`
    query AllJobs {
  AllJobs {
    _id
    title
    hideSalary
    countries {
      _id
      name
      code
    }
    salaryPackage
    experience
    description
    requiredSkills {
      _id
      title
    }
    company {
      _id
      name
      logoUrl
      users {
        _id
        email
      }
    }
    currency {
      _id
      name
      symbol
    }
    leadRecruiter {
      _id
      email
    }
    fields {
      _id
      title
    }
    contractType {
      _id
      name
    }
    createdAt
    targetCompanies {
      _id
      name
    }
    recruiters {
      email
      roles
    }
    status
    isAnonymous
    AnonymousCompanyTitle
    AnonymousCompanyDescription
    candidates {
      _id
    }
  }
}
    `;

/**
 * __useAllJobsQuery__
 *
 * To run a query within a React component, call `useAllJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllJobsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllJobsQuery, AllJobsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllJobsQuery, AllJobsQueryVariables>(AllJobsDocument, baseOptions);
      }
export function useAllJobsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllJobsQuery, AllJobsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllJobsQuery, AllJobsQueryVariables>(AllJobsDocument, baseOptions);
        }
export type AllJobsQueryHookResult = ReturnType<typeof useAllJobsQuery>;
export type AllJobsLazyQueryHookResult = ReturnType<typeof useAllJobsLazyQuery>;
export type AllJobsQueryResult = ApolloReactCommon.QueryResult<AllJobsQuery, AllJobsQueryVariables>;
export const CompanyJobsDocument = gql`
    query companyJobs($companyId: ID!) {
  getJobs(companyId: $companyId) {
    _id
    title
    hideSalary
    countries {
      _id
      name
      code
    }
    salaryPackage
    experience
    description
    requiredSkills {
      _id
      title
    }
    company {
      _id
      name
    }
    currency {
      _id
      name
      symbol
    }
    leadRecruiter {
      _id
      email
    }
    fields {
      _id
      title
    }
    contractType {
      _id
      name
    }
    createdAt
    targetCompanies {
      _id
      name
    }
    recruiters {
      email
      roles
    }
    candidates {
      _id
    }
    status
  }
}
    `;

/**
 * __useCompanyJobsQuery__
 *
 * To run a query within a React component, call `useCompanyJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyJobsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyJobsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyJobsQuery, CompanyJobsQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyJobsQuery, CompanyJobsQueryVariables>(CompanyJobsDocument, baseOptions);
      }
export function useCompanyJobsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyJobsQuery, CompanyJobsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyJobsQuery, CompanyJobsQueryVariables>(CompanyJobsDocument, baseOptions);
        }
export type CompanyJobsQueryHookResult = ReturnType<typeof useCompanyJobsQuery>;
export type CompanyJobsLazyQueryHookResult = ReturnType<typeof useCompanyJobsLazyQuery>;
export type CompanyJobsQueryResult = ApolloReactCommon.QueryResult<CompanyJobsQuery, CompanyJobsQueryVariables>;
export const GetCompanyDetailFromJobDocument = gql`
    query getCompanyDetailFromJob($jobId: String!) {
  getCompanyFromJob(jobId: $jobId) {
    name
    jobTitle
    _id
  }
}
    `;

/**
 * __useGetCompanyDetailFromJobQuery__
 *
 * To run a query within a React component, call `useGetCompanyDetailFromJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDetailFromJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDetailFromJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetCompanyDetailFromJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyDetailFromJobQuery, GetCompanyDetailFromJobQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyDetailFromJobQuery, GetCompanyDetailFromJobQueryVariables>(GetCompanyDetailFromJobDocument, baseOptions);
      }
export function useGetCompanyDetailFromJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyDetailFromJobQuery, GetCompanyDetailFromJobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyDetailFromJobQuery, GetCompanyDetailFromJobQueryVariables>(GetCompanyDetailFromJobDocument, baseOptions);
        }
export type GetCompanyDetailFromJobQueryHookResult = ReturnType<typeof useGetCompanyDetailFromJobQuery>;
export type GetCompanyDetailFromJobLazyQueryHookResult = ReturnType<typeof useGetCompanyDetailFromJobLazyQuery>;
export type GetCompanyDetailFromJobQueryResult = ApolloReactCommon.QueryResult<GetCompanyDetailFromJobQuery, GetCompanyDetailFromJobQueryVariables>;
export const ContractTypesDocument = gql`
    query contractTypes {
  contracts {
    _id
    name
  }
}
    `;

/**
 * __useContractTypesQuery__
 *
 * To run a query within a React component, call `useContractTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useContractTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContractTypesQuery, ContractTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<ContractTypesQuery, ContractTypesQueryVariables>(ContractTypesDocument, baseOptions);
      }
export function useContractTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContractTypesQuery, ContractTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContractTypesQuery, ContractTypesQueryVariables>(ContractTypesDocument, baseOptions);
        }
export type ContractTypesQueryHookResult = ReturnType<typeof useContractTypesQuery>;
export type ContractTypesLazyQueryHookResult = ReturnType<typeof useContractTypesLazyQuery>;
export type ContractTypesQueryResult = ApolloReactCommon.QueryResult<ContractTypesQuery, ContractTypesQueryVariables>;
export const CountriesDocument = gql`
    query Countries {
  countries {
    _id
    name
    code
  }
}
    `;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        return ApolloReactHooks.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, baseOptions);
      }
export function useCountriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, baseOptions);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = ApolloReactCommon.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const CreateCountryDocument = gql`
    mutation createCountry($name: [String]) {
  createCountry(name: $name) {
    _id
    name
    code
  }
}
    `;
export type CreateCountryMutationFn = ApolloReactCommon.MutationFunction<CreateCountryMutation, CreateCountryMutationVariables>;

/**
 * __useCreateCountryMutation__
 *
 * To run a mutation, you first call `useCreateCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCountryMutation, { data, loading, error }] = useCreateCountryMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCountryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCountryMutation, CreateCountryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCountryMutation, CreateCountryMutationVariables>(CreateCountryDocument, baseOptions);
      }
export type CreateCountryMutationHookResult = ReturnType<typeof useCreateCountryMutation>;
export type CreateCountryMutationResult = ApolloReactCommon.MutationResult<CreateCountryMutation>;
export type CreateCountryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCountryMutation, CreateCountryMutationVariables>;
export const CurrenciesDocument = gql`
    query Currencies {
  currencies {
    _id
    name
    symbol
  }
}
    `;

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrenciesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, baseOptions);
      }
export function useCurrenciesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, baseOptions);
        }
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<typeof useCurrenciesLazyQuery>;
export type CurrenciesQueryResult = ApolloReactCommon.QueryResult<CurrenciesQuery, CurrenciesQueryVariables>;
export const DeleteJobDocument = gql`
    mutation deleteJob($jobId: String) {
  deleteJob(jobId: $jobId) {
    _id
    title
    hideSalary
    countries {
      _id
      name
      code
    }
    experience
    salaryPackage
    description
    requiredSkills {
      _id
      title
    }
    company {
      _id
      name
      logoUrl
      users {
        _id
        email
      }
    }
    recruiters {
      _id
      email
      roles
    }
    leadRecruiter {
      _id
      email
    }
    fields {
      _id
      title
    }
    contractType {
      _id
      name
    }
    createdAt
    targetCompanies {
      _id
      name
    }
    currency {
      _id
      name
      symbol
    }
    status
  }
}
    `;
export type DeleteJobMutationFn = ApolloReactCommon.MutationFunction<DeleteJobMutation, DeleteJobMutationVariables>;

/**
 * __useDeleteJobMutation__
 *
 * To run a mutation, you first call `useDeleteJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobMutation, { data, loading, error }] = useDeleteJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useDeleteJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteJobMutation, DeleteJobMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteJobMutation, DeleteJobMutationVariables>(DeleteJobDocument, baseOptions);
      }
export type DeleteJobMutationHookResult = ReturnType<typeof useDeleteJobMutation>;
export type DeleteJobMutationResult = ApolloReactCommon.MutationResult<DeleteJobMutation>;
export type DeleteJobMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteJobMutation, DeleteJobMutationVariables>;
export const DisLikesAgainstAJobDocument = gql`
    query disLikesAgainstAJob($jobId: String!) {
  disLikesAgainstAJob(jobId: $jobId) {
    _id
    candidateListing {
      _id
      userId
      firstName
      lastName
      resume
      position
      profilePicture
      company
      experiences {
        position
        company
        startYear
      }
      user {
        email
      }
      currentLocation {
        coordinates
        country
        city
        code
      }
    }
    jobListing {
      _id
      title
      description
    }
    company {
      name
      description
      logoUrl
    }
    score
    jobScore
    activationScore
    recruiterAction
    candidateAction
    lastAction
    isProfileCompleted
    bypass
    media
    isDeleted
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useDisLikesAgainstAJobQuery__
 *
 * To run a query within a React component, call `useDisLikesAgainstAJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisLikesAgainstAJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisLikesAgainstAJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useDisLikesAgainstAJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DisLikesAgainstAJobQuery, DisLikesAgainstAJobQueryVariables>) {
        return ApolloReactHooks.useQuery<DisLikesAgainstAJobQuery, DisLikesAgainstAJobQueryVariables>(DisLikesAgainstAJobDocument, baseOptions);
      }
export function useDisLikesAgainstAJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DisLikesAgainstAJobQuery, DisLikesAgainstAJobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DisLikesAgainstAJobQuery, DisLikesAgainstAJobQueryVariables>(DisLikesAgainstAJobDocument, baseOptions);
        }
export type DisLikesAgainstAJobQueryHookResult = ReturnType<typeof useDisLikesAgainstAJobQuery>;
export type DisLikesAgainstAJobLazyQueryHookResult = ReturnType<typeof useDisLikesAgainstAJobLazyQuery>;
export type DisLikesAgainstAJobQueryResult = ApolloReactCommon.QueryResult<DisLikesAgainstAJobQuery, DisLikesAgainstAJobQueryVariables>;
export const FavouritesAgainstAJobDocument = gql`
    query favouritesAgainstAJob($jobId: String!) {
  favouritesAgainstAJob(jobId: $jobId) {
    _id
    candidateListing {
      _id
      userId
      firstName
      lastName
      resume
      position
      profilePicture
      experiences {
        position
        company
        startYear
      }
      user {
        email
      }
      currentLocation {
        coordinates
        country
        city
        code
      }
    }
    jobListing {
      _id
      title
      description
    }
    company {
      name
      description
      logoUrl
    }
    score
    jobScore
    activationScore
    recruiterAction
    candidateAction
    isProfileCompleted
    bypass
    media
    isDeleted
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useFavouritesAgainstAJobQuery__
 *
 * To run a query within a React component, call `useFavouritesAgainstAJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavouritesAgainstAJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavouritesAgainstAJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useFavouritesAgainstAJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FavouritesAgainstAJobQuery, FavouritesAgainstAJobQueryVariables>) {
        return ApolloReactHooks.useQuery<FavouritesAgainstAJobQuery, FavouritesAgainstAJobQueryVariables>(FavouritesAgainstAJobDocument, baseOptions);
      }
export function useFavouritesAgainstAJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FavouritesAgainstAJobQuery, FavouritesAgainstAJobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FavouritesAgainstAJobQuery, FavouritesAgainstAJobQueryVariables>(FavouritesAgainstAJobDocument, baseOptions);
        }
export type FavouritesAgainstAJobQueryHookResult = ReturnType<typeof useFavouritesAgainstAJobQuery>;
export type FavouritesAgainstAJobLazyQueryHookResult = ReturnType<typeof useFavouritesAgainstAJobLazyQuery>;
export type FavouritesAgainstAJobQueryResult = ApolloReactCommon.QueryResult<FavouritesAgainstAJobQuery, FavouritesAgainstAJobQueryVariables>;
export const GetMatchDocument = gql`
    query getMatch($matchId: String!) {
  getMatch(matchId: $matchId) {
    _id
    candidateListing {
      _id
      userId
      firstName
      lastName
      resume
      position
      description
      personalInformation
      benchMark
      profilePicture
      skills {
        _id
        title
      }
      user {
        email
      }
      countries {
        name
        code
      }
      currentLocation {
        coordinates
        country
        city
        code
      }
      isProfileCompleted
    }
    AssessmentReport {
      _id
      matchId
      hideExperience
      hideTraits
      hideDrivers
      experience {
        title
        level
        comment
      }
      traits {
        title
        level
        comment
      }
      drivers {
        title
        level
        comment
      }
    }
    benchmark
    showCurrentCompensation
    showExpectedCompensation
    hidePersonalDetails
    nextSteps
    score
    jobScore
    activationScore
    recruiterAction
    lastAction
    candidateAction
    bypass
    media
    isDeleted
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetMatchQuery__
 *
 * To run a query within a React component, call `useGetMatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchQuery({
 *   variables: {
 *      matchId: // value for 'matchId'
 *   },
 * });
 */
export function useGetMatchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMatchQuery, GetMatchQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMatchQuery, GetMatchQueryVariables>(GetMatchDocument, baseOptions);
      }
export function useGetMatchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMatchQuery, GetMatchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMatchQuery, GetMatchQueryVariables>(GetMatchDocument, baseOptions);
        }
export type GetMatchQueryHookResult = ReturnType<typeof useGetMatchQuery>;
export type GetMatchLazyQueryHookResult = ReturnType<typeof useGetMatchLazyQuery>;
export type GetMatchQueryResult = ApolloReactCommon.QueryResult<GetMatchQuery, GetMatchQueryVariables>;
export const HiredAgainstAJobDocument = gql`
    query hiredAgainstAJob($jobId: String!) {
  hiredAgainstAJob(jobId: $jobId) {
    _id
    candidateListing {
      _id
      userId
      firstName
      lastName
      resume
      position
      profilePicture
      company
      experiences {
        position
        company
        startYear
      }
      user {
        email
      }
      currentLocation {
        coordinates
        country
        city
        code
      }
    }
    jobListing {
      _id
      title
      description
    }
    company {
      name
      description
      logoUrl
    }
    benchmark
    score
    jobScore
    activationScore
    recruiterAction
    candidateAction
    isProfileCompleted
    bypass
    media
    isDeleted
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useHiredAgainstAJobQuery__
 *
 * To run a query within a React component, call `useHiredAgainstAJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useHiredAgainstAJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHiredAgainstAJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useHiredAgainstAJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HiredAgainstAJobQuery, HiredAgainstAJobQueryVariables>) {
        return ApolloReactHooks.useQuery<HiredAgainstAJobQuery, HiredAgainstAJobQueryVariables>(HiredAgainstAJobDocument, baseOptions);
      }
export function useHiredAgainstAJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HiredAgainstAJobQuery, HiredAgainstAJobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HiredAgainstAJobQuery, HiredAgainstAJobQueryVariables>(HiredAgainstAJobDocument, baseOptions);
        }
export type HiredAgainstAJobQueryHookResult = ReturnType<typeof useHiredAgainstAJobQuery>;
export type HiredAgainstAJobLazyQueryHookResult = ReturnType<typeof useHiredAgainstAJobLazyQuery>;
export type HiredAgainstAJobQueryResult = ApolloReactCommon.QueryResult<HiredAgainstAJobQuery, HiredAgainstAJobQueryVariables>;
export const InspiringCompaniesDocument = gql`
    query inspiringCompanies {
  inspiringCompanies {
    _id
    name
  }
}
    `;

/**
 * __useInspiringCompaniesQuery__
 *
 * To run a query within a React component, call `useInspiringCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInspiringCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInspiringCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInspiringCompaniesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InspiringCompaniesQuery, InspiringCompaniesQueryVariables>) {
        return ApolloReactHooks.useQuery<InspiringCompaniesQuery, InspiringCompaniesQueryVariables>(InspiringCompaniesDocument, baseOptions);
      }
export function useInspiringCompaniesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InspiringCompaniesQuery, InspiringCompaniesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InspiringCompaniesQuery, InspiringCompaniesQueryVariables>(InspiringCompaniesDocument, baseOptions);
        }
export type InspiringCompaniesQueryHookResult = ReturnType<typeof useInspiringCompaniesQuery>;
export type InspiringCompaniesLazyQueryHookResult = ReturnType<typeof useInspiringCompaniesLazyQuery>;
export type InspiringCompaniesQueryResult = ApolloReactCommon.QueryResult<InspiringCompaniesQuery, InspiringCompaniesQueryVariables>;
export const JobFieldsDocument = gql`
    query JobFields {
  jobFields {
    _id
    title
    parent {
      _id
      title
    }
  }
}
    `;

/**
 * __useJobFieldsQuery__
 *
 * To run a query within a React component, call `useJobFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobFieldsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobFieldsQuery, JobFieldsQueryVariables>) {
        return ApolloReactHooks.useQuery<JobFieldsQuery, JobFieldsQueryVariables>(JobFieldsDocument, baseOptions);
      }
export function useJobFieldsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobFieldsQuery, JobFieldsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobFieldsQuery, JobFieldsQueryVariables>(JobFieldsDocument, baseOptions);
        }
export type JobFieldsQueryHookResult = ReturnType<typeof useJobFieldsQuery>;
export type JobFieldsLazyQueryHookResult = ReturnType<typeof useJobFieldsLazyQuery>;
export type JobFieldsQueryResult = ApolloReactCommon.QueryResult<JobFieldsQuery, JobFieldsQueryVariables>;
export const LikesAgainstAJobDocument = gql`
    query likesAgainstAJob($jobId: String!) {
  likesAgainstAJob(jobId: $jobId) {
    _id
    candidateListing {
      _id
      userId
      firstName
      lastName
      resume
      position
      profilePicture
      experiences {
        position
        company
        startYear
      }
      user {
        email
      }
      currentLocation {
        coordinates
        country
        city
        code
      }
    }
    jobListing {
      _id
      title
      description
    }
    company {
      name
      description
      logoUrl
    }
    score
    jobScore
    activationScore
    recruiterAction
    candidateAction
    isProfileCompleted
    bypass
    media
    isDeleted
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useLikesAgainstAJobQuery__
 *
 * To run a query within a React component, call `useLikesAgainstAJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useLikesAgainstAJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLikesAgainstAJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useLikesAgainstAJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LikesAgainstAJobQuery, LikesAgainstAJobQueryVariables>) {
        return ApolloReactHooks.useQuery<LikesAgainstAJobQuery, LikesAgainstAJobQueryVariables>(LikesAgainstAJobDocument, baseOptions);
      }
export function useLikesAgainstAJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LikesAgainstAJobQuery, LikesAgainstAJobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LikesAgainstAJobQuery, LikesAgainstAJobQueryVariables>(LikesAgainstAJobDocument, baseOptions);
        }
export type LikesAgainstAJobQueryHookResult = ReturnType<typeof useLikesAgainstAJobQuery>;
export type LikesAgainstAJobLazyQueryHookResult = ReturnType<typeof useLikesAgainstAJobLazyQuery>;
export type LikesAgainstAJobQueryResult = ApolloReactCommon.QueryResult<LikesAgainstAJobQuery, LikesAgainstAJobQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  recruiterLogin(email: $email, password: $password) {
    _id
    email
    userId
    fullName
    title
    roles
    verified
    subscriptionPlanId
    token
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const MatchesAgainstAJobDocument = gql`
    query matchesAgainstAJob($jobId: String!) {
  matchesAgainstAJob(jobId: $jobId) {
    _id
    candidateListing {
      _id
      userId
      firstName
      lastName
      resume
      position
      profilePicture
      company
      experiences {
        position
        company
        startYear
      }
      user {
        email
      }
      currentLocation {
        coordinates
        country
        city
        code
      }
    }
    jobListing {
      _id
      title
      description
    }
    benchmark
    company {
      name
      description
      logoUrl
    }
    score
    jobScore
    activationScore
    recruiterAction
    candidateAction
    isProfileCompleted
    bypass
    media
    isDeleted
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useMatchesAgainstAJobQuery__
 *
 * To run a query within a React component, call `useMatchesAgainstAJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchesAgainstAJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchesAgainstAJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useMatchesAgainstAJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MatchesAgainstAJobQuery, MatchesAgainstAJobQueryVariables>) {
        return ApolloReactHooks.useQuery<MatchesAgainstAJobQuery, MatchesAgainstAJobQueryVariables>(MatchesAgainstAJobDocument, baseOptions);
      }
export function useMatchesAgainstAJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MatchesAgainstAJobQuery, MatchesAgainstAJobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MatchesAgainstAJobQuery, MatchesAgainstAJobQueryVariables>(MatchesAgainstAJobDocument, baseOptions);
        }
export type MatchesAgainstAJobQueryHookResult = ReturnType<typeof useMatchesAgainstAJobQuery>;
export type MatchesAgainstAJobLazyQueryHookResult = ReturnType<typeof useMatchesAgainstAJobLazyQuery>;
export type MatchesAgainstAJobQueryResult = ApolloReactCommon.QueryResult<MatchesAgainstAJobQuery, MatchesAgainstAJobQueryVariables>;
export const MatchesForAJobDocument = gql`
    query matchesForAJob($jobId: String!, $searchParams: SearchParamsInput) {
  matchesForAJob(jobId: $jobId, searchParams: $searchParams) {
    _id
    jobListing {
      _id
      title
      description
    }
    candidateListing {
      _id
      userId
      firstName
      lastName
      resume
      position
      experiences {
        position
        company
        startYear
      }
      user {
        email
      }
      countries {
        name
        code
      }
      location {
        coordinates
        country
        city
        code
      }
      isProfileCompleted
    }
    score
    jobScore
    activationScore
    recruiterAction
    candidateAction
    bypass
    media
    isDeleted
    createdAt
    updatedAt
    matchingAlgoScores {
      candidateScore
      jobScore
    }
  }
}
    `;

/**
 * __useMatchesForAJobQuery__
 *
 * To run a query within a React component, call `useMatchesForAJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchesForAJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchesForAJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      searchParams: // value for 'searchParams'
 *   },
 * });
 */
export function useMatchesForAJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MatchesForAJobQuery, MatchesForAJobQueryVariables>) {
        return ApolloReactHooks.useQuery<MatchesForAJobQuery, MatchesForAJobQueryVariables>(MatchesForAJobDocument, baseOptions);
      }
export function useMatchesForAJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MatchesForAJobQuery, MatchesForAJobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MatchesForAJobQuery, MatchesForAJobQueryVariables>(MatchesForAJobDocument, baseOptions);
        }
export type MatchesForAJobQueryHookResult = ReturnType<typeof useMatchesForAJobQuery>;
export type MatchesForAJobLazyQueryHookResult = ReturnType<typeof useMatchesForAJobLazyQuery>;
export type MatchesForAJobQueryResult = ApolloReactCommon.QueryResult<MatchesForAJobQuery, MatchesForAJobQueryVariables>;
export const NewCandidatesAgainstAJobDocument = gql`
    query newCandidatesAgainstAJob($jobId: String!) {
  newCandidatesAgainstAJob(jobId: $jobId) {
    _id
    candidateListing {
      _id
      userId
      firstName
      lastName
      resume
      profilePicture
      position
      company
      experiences {
        position
        company
        startYear
      }
      user {
        email
      }
      currentLocation {
        coordinates
        country
        city
        code
      }
    }
    jobListing {
      _id
      title
      description
    }
    company {
      name
      description
      logoUrl
    }
    benchmark
    score
    jobScore
    activationScore
    recruiterAction
    candidateAction
    isProfileCompleted
    bypass
    media
    isDeleted
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useNewCandidatesAgainstAJobQuery__
 *
 * To run a query within a React component, call `useNewCandidatesAgainstAJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewCandidatesAgainstAJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewCandidatesAgainstAJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useNewCandidatesAgainstAJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewCandidatesAgainstAJobQuery, NewCandidatesAgainstAJobQueryVariables>) {
        return ApolloReactHooks.useQuery<NewCandidatesAgainstAJobQuery, NewCandidatesAgainstAJobQueryVariables>(NewCandidatesAgainstAJobDocument, baseOptions);
      }
export function useNewCandidatesAgainstAJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewCandidatesAgainstAJobQuery, NewCandidatesAgainstAJobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewCandidatesAgainstAJobQuery, NewCandidatesAgainstAJobQueryVariables>(NewCandidatesAgainstAJobDocument, baseOptions);
        }
export type NewCandidatesAgainstAJobQueryHookResult = ReturnType<typeof useNewCandidatesAgainstAJobQuery>;
export type NewCandidatesAgainstAJobLazyQueryHookResult = ReturnType<typeof useNewCandidatesAgainstAJobLazyQuery>;
export type NewCandidatesAgainstAJobQueryResult = ApolloReactCommon.QueryResult<NewCandidatesAgainstAJobQuery, NewCandidatesAgainstAJobQueryVariables>;
export const RecruiterActionDocument = gql`
    mutation recruiterAction($matchId: String!, $action: String!, $from: String, $lastAction: String) {
  recruiterAction(
    matchId: $matchId
    action: $action
    from: $from
    lastAction: $lastAction
  ) {
    _id
    candidateListing {
      _id
      userId
      firstName
      lastName
      resume
      position
      experiences {
        position
        company
        startYear
      }
      user {
        email
      }
      currentLocation {
        coordinates
        country
        city
        code
      }
    }
    score
    jobScore
    activationScore
    recruiterAction
    candidateAction
    lastAction
    isProfileCompleted
    bypass
    media
    isDeleted
    createdAt
    updatedAt
  }
}
    `;
export type RecruiterActionMutationFn = ApolloReactCommon.MutationFunction<RecruiterActionMutation, RecruiterActionMutationVariables>;

/**
 * __useRecruiterActionMutation__
 *
 * To run a mutation, you first call `useRecruiterActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecruiterActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recruiterActionMutation, { data, loading, error }] = useRecruiterActionMutation({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      action: // value for 'action'
 *      from: // value for 'from'
 *      lastAction: // value for 'lastAction'
 *   },
 * });
 */
export function useRecruiterActionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecruiterActionMutation, RecruiterActionMutationVariables>) {
        return ApolloReactHooks.useMutation<RecruiterActionMutation, RecruiterActionMutationVariables>(RecruiterActionDocument, baseOptions);
      }
export type RecruiterActionMutationHookResult = ReturnType<typeof useRecruiterActionMutation>;
export type RecruiterActionMutationResult = ApolloReactCommon.MutationResult<RecruiterActionMutation>;
export type RecruiterActionMutationOptions = ApolloReactCommon.BaseMutationOptions<RecruiterActionMutation, RecruiterActionMutationVariables>;
export const SendMessageDocument = gql`
    mutation sendMessage($matchId: String!, $action: String!, $message: String) {
  messageTriggerMailRecruiter(
    matchId: $matchId
    action: $action
    message: $message
  ) {
    message
  }
}
    `;
export type SendMessageMutationFn = ApolloReactCommon.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      action: // value for 'action'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, baseOptions);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = ApolloReactCommon.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const SendMessageToCandidateDocument = gql`
    mutation sendMessageToCandidate($sendTo: String!, $myRole: String!, $message: String!, $payload: MessagePayload) {
  sendMessage(
    sendTo: $sendTo
    myRole: $myRole
    message: $message
    payload: $payload
  ) {
    message
    status
  }
}
    `;
export type SendMessageToCandidateMutationFn = ApolloReactCommon.MutationFunction<SendMessageToCandidateMutation, SendMessageToCandidateMutationVariables>;

/**
 * __useSendMessageToCandidateMutation__
 *
 * To run a mutation, you first call `useSendMessageToCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageToCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageToCandidateMutation, { data, loading, error }] = useSendMessageToCandidateMutation({
 *   variables: {
 *      sendTo: // value for 'sendTo'
 *      myRole: // value for 'myRole'
 *      message: // value for 'message'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSendMessageToCandidateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendMessageToCandidateMutation, SendMessageToCandidateMutationVariables>) {
        return ApolloReactHooks.useMutation<SendMessageToCandidateMutation, SendMessageToCandidateMutationVariables>(SendMessageToCandidateDocument, baseOptions);
      }
export type SendMessageToCandidateMutationHookResult = ReturnType<typeof useSendMessageToCandidateMutation>;
export type SendMessageToCandidateMutationResult = ApolloReactCommon.MutationResult<SendMessageToCandidateMutation>;
export type SendMessageToCandidateMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMessageToCandidateMutation, SendMessageToCandidateMutationVariables>;
export const RejectedAgainstAJobDocument = gql`
    query rejectedAgainstAJob($jobId: String!) {
  rejectedAgainstAJob(jobId: $jobId) {
    _id
    candidateListing {
      _id
      userId
      firstName
      lastName
      resume
      position
      profilePicture
      company
      experiences {
        position
        company
        startYear
      }
      user {
        email
      }
      currentLocation {
        coordinates
        country
        city
        code
      }
    }
    jobListing {
      _id
      title
      description
    }
    company {
      name
      description
      logoUrl
    }
    score
    jobScore
    activationScore
    recruiterAction
    candidateAction
    isProfileCompleted
    bypass
    media
    isDeleted
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useRejectedAgainstAJobQuery__
 *
 * To run a query within a React component, call `useRejectedAgainstAJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useRejectedAgainstAJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRejectedAgainstAJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useRejectedAgainstAJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RejectedAgainstAJobQuery, RejectedAgainstAJobQueryVariables>) {
        return ApolloReactHooks.useQuery<RejectedAgainstAJobQuery, RejectedAgainstAJobQueryVariables>(RejectedAgainstAJobDocument, baseOptions);
      }
export function useRejectedAgainstAJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RejectedAgainstAJobQuery, RejectedAgainstAJobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RejectedAgainstAJobQuery, RejectedAgainstAJobQueryVariables>(RejectedAgainstAJobDocument, baseOptions);
        }
export type RejectedAgainstAJobQueryHookResult = ReturnType<typeof useRejectedAgainstAJobQuery>;
export type RejectedAgainstAJobLazyQueryHookResult = ReturnType<typeof useRejectedAgainstAJobLazyQuery>;
export type RejectedAgainstAJobQueryResult = ApolloReactCommon.QueryResult<RejectedAgainstAJobQuery, RejectedAgainstAJobQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!, $password: String!) {
  recruiterResetPassword(email: $email, password: $password) {
    response {
      message
    }
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResetPasswordRequestDocument = gql`
    mutation ResetPasswordRequest($email: String!) {
  sendPasswordResetEmail(email: $email) {
    message
  }
}
    `;
export type ResetPasswordRequestMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordRequestMutation, ResetPasswordRequestMutationVariables>;

/**
 * __useResetPasswordRequestMutation__
 *
 * To run a mutation, you first call `useResetPasswordRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordRequestMutation, { data, loading, error }] = useResetPasswordRequestMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordRequestMutation, ResetPasswordRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordRequestMutation, ResetPasswordRequestMutationVariables>(ResetPasswordRequestDocument, baseOptions);
      }
export type ResetPasswordRequestMutationHookResult = ReturnType<typeof useResetPasswordRequestMutation>;
export type ResetPasswordRequestMutationResult = ApolloReactCommon.MutationResult<ResetPasswordRequestMutation>;
export type ResetPasswordRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordRequestMutation, ResetPasswordRequestMutationVariables>;
export const SectorsDocument = gql`
    query Sectors {
  sectors {
    _id
    title
  }
}
    `;

/**
 * __useSectorsQuery__
 *
 * To run a query within a React component, call `useSectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSectorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SectorsQuery, SectorsQueryVariables>) {
        return ApolloReactHooks.useQuery<SectorsQuery, SectorsQueryVariables>(SectorsDocument, baseOptions);
      }
export function useSectorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SectorsQuery, SectorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SectorsQuery, SectorsQueryVariables>(SectorsDocument, baseOptions);
        }
export type SectorsQueryHookResult = ReturnType<typeof useSectorsQuery>;
export type SectorsLazyQueryHookResult = ReturnType<typeof useSectorsLazyQuery>;
export type SectorsQueryResult = ApolloReactCommon.QueryResult<SectorsQuery, SectorsQueryVariables>;
export const SelectedAgainstAJobDocument = gql`
    query selectedAgainstAJob($jobId: String!) {
  selectedAgainstAJob(jobId: $jobId) {
    _id
    candidateListing {
      _id
      userId
      firstName
      lastName
      resume
      position
      profilePicture
      company
      experiences {
        position
        company
        startYear
      }
      user {
        email
      }
      currentLocation {
        coordinates
        country
        city
        code
      }
    }
    jobListing {
      _id
      title
      description
    }
    company {
      name
      description
      logoUrl
    }
    benchmark
    score
    jobScore
    activationScore
    recruiterAction
    candidateAction
    isProfileCompleted
    bypass
    media
    isDeleted
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useSelectedAgainstAJobQuery__
 *
 * To run a query within a React component, call `useSelectedAgainstAJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedAgainstAJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedAgainstAJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useSelectedAgainstAJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SelectedAgainstAJobQuery, SelectedAgainstAJobQueryVariables>) {
        return ApolloReactHooks.useQuery<SelectedAgainstAJobQuery, SelectedAgainstAJobQueryVariables>(SelectedAgainstAJobDocument, baseOptions);
      }
export function useSelectedAgainstAJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SelectedAgainstAJobQuery, SelectedAgainstAJobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SelectedAgainstAJobQuery, SelectedAgainstAJobQueryVariables>(SelectedAgainstAJobDocument, baseOptions);
        }
export type SelectedAgainstAJobQueryHookResult = ReturnType<typeof useSelectedAgainstAJobQuery>;
export type SelectedAgainstAJobLazyQueryHookResult = ReturnType<typeof useSelectedAgainstAJobLazyQuery>;
export type SelectedAgainstAJobQueryResult = ApolloReactCommon.QueryResult<SelectedAgainstAJobQuery, SelectedAgainstAJobQueryVariables>;
export const RecruiterSignupDocument = gql`
    mutation recruiterSignup($fullName: String!, $email: String!, $password: String!, $title: String!, $company: String!, $location: UpdateLocationExperienceAndEducation!) {
  recruiterSignup(
    fullName: $fullName
    email: $email
    password: $password
    title: $title
    company: $company
    location: $location
  ) {
    _id
    userId
    title
    subscriptionPlanId
    fullName
    email
    token
  }
}
    `;
export type RecruiterSignupMutationFn = ApolloReactCommon.MutationFunction<RecruiterSignupMutation, RecruiterSignupMutationVariables>;

/**
 * __useRecruiterSignupMutation__
 *
 * To run a mutation, you first call `useRecruiterSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecruiterSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recruiterSignupMutation, { data, loading, error }] = useRecruiterSignupMutation({
 *   variables: {
 *      fullName: // value for 'fullName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      title: // value for 'title'
 *      company: // value for 'company'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useRecruiterSignupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecruiterSignupMutation, RecruiterSignupMutationVariables>) {
        return ApolloReactHooks.useMutation<RecruiterSignupMutation, RecruiterSignupMutationVariables>(RecruiterSignupDocument, baseOptions);
      }
export type RecruiterSignupMutationHookResult = ReturnType<typeof useRecruiterSignupMutation>;
export type RecruiterSignupMutationResult = ApolloReactCommon.MutationResult<RecruiterSignupMutation>;
export type RecruiterSignupMutationOptions = ApolloReactCommon.BaseMutationOptions<RecruiterSignupMutation, RecruiterSignupMutationVariables>;
export const VerifyRecruiterSignupDocument = gql`
    mutation VerifyRecruiterSignup($userId: String!, $code: String!) {
  VerifyRecruiterSignup(userId: $userId, code: $code) {
    message
  }
}
    `;
export type VerifyRecruiterSignupMutationFn = ApolloReactCommon.MutationFunction<VerifyRecruiterSignupMutation, VerifyRecruiterSignupMutationVariables>;

/**
 * __useVerifyRecruiterSignupMutation__
 *
 * To run a mutation, you first call `useVerifyRecruiterSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyRecruiterSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyRecruiterSignupMutation, { data, loading, error }] = useVerifyRecruiterSignupMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyRecruiterSignupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyRecruiterSignupMutation, VerifyRecruiterSignupMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyRecruiterSignupMutation, VerifyRecruiterSignupMutationVariables>(VerifyRecruiterSignupDocument, baseOptions);
      }
export type VerifyRecruiterSignupMutationHookResult = ReturnType<typeof useVerifyRecruiterSignupMutation>;
export type VerifyRecruiterSignupMutationResult = ApolloReactCommon.MutationResult<VerifyRecruiterSignupMutation>;
export type VerifyRecruiterSignupMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyRecruiterSignupMutation, VerifyRecruiterSignupMutationVariables>;
export const GetCandidateDocument = gql`
    query getCandidate($candidateId: String!) {
  getCandidate(candidateId: $candidateId) {
    _id
    firstName
    lastName
    position
    description
    personalInformation
    facebook
    github
    linkedIn
    twitter
    benchMark
    totalExperience
    coverPhoto
    educationComment
    experiences {
      position
      company
      startYear
      endYear
      current
      jobField
      reasonForChange
      comments
      commentData {
        candidateId
        company
        comments
        reasonForChange
      }
      location {
        country
        city
        code
        coordinates {
          lat
          lng
        }
      }
      logo
    }
    resume
    coverLetter
    education {
      schoolName
      degree
      startYear
      current
      endYear
      location {
        country
        city
        code
        coordinates {
          lat
          lng
        }
      }
      logo
    }
    isPublic
    inspiringCompanies {
      _id
      name
    }
    currentLocation {
      country
      city
      code
      coordinates
    }
    profilePicture
    profileVideo
    objectives {
      _id
      title
    }
    professionalInterests {
      _id
      title
    }
    targetRoles
    desiredSalaryPackage
    currentSalaryPackage
    isOnlyNetworker
    isDeleted
    token
    subscriptionPlanId
    createdAt
    updatedAt
    appliedJobs
    tags {
      _id
      name
      color
    }
    contractIdType
    sectors {
      _id
      title
    }
    company
    userId
    skills {
      _id
      title
    }
    isProfileCompleted
    user {
      _id
      email
    }
    targetLocations {
      _id
      name
      city
    }
    languages {
      language
      level
    }
    languageComments
    yearlyCompensation {
      current {
        base
        onTargetEarnings
        shortTermIncentives
        longTermIncentives
        other
        total
      }
      expected {
        base
        onTargetEarnings
        shortTermIncentives
        longTermIncentives
        other
        total
      }
      comments
      currentComments
      currency
    }
    nextSteps
  }
}
    `;

/**
 * __useGetCandidateQuery__
 *
 * To run a query within a React component, call `useGetCandidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useGetCandidateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCandidateQuery, GetCandidateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCandidateQuery, GetCandidateQueryVariables>(GetCandidateDocument, baseOptions);
      }
export function useGetCandidateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCandidateQuery, GetCandidateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCandidateQuery, GetCandidateQueryVariables>(GetCandidateDocument, baseOptions);
        }
export type GetCandidateQueryHookResult = ReturnType<typeof useGetCandidateQuery>;
export type GetCandidateLazyQueryHookResult = ReturnType<typeof useGetCandidateLazyQuery>;
export type GetCandidateQueryResult = ApolloReactCommon.QueryResult<GetCandidateQuery, GetCandidateQueryVariables>;
export const GetSingleCompanyDocument = gql`
    query getSingleCompany($companyId: String!) {
  getSingleCompany(companyId: $companyId) {
    _id
    name
    description
    sector {
      title
    }
    website
    companySize
    logoUrl
    banner
    createdAt
    createdBy {
      _id
      email
    }
    facebook
    linkedIn
    twitter
    users {
      _id
      email
    }
    jobs {
      _id
      title
    }
    totalJobs
  }
}
    `;

/**
 * __useGetSingleCompanyQuery__
 *
 * To run a query within a React component, call `useGetSingleCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetSingleCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSingleCompanyQuery, GetSingleCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSingleCompanyQuery, GetSingleCompanyQueryVariables>(GetSingleCompanyDocument, baseOptions);
      }
export function useGetSingleCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSingleCompanyQuery, GetSingleCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSingleCompanyQuery, GetSingleCompanyQueryVariables>(GetSingleCompanyDocument, baseOptions);
        }
export type GetSingleCompanyQueryHookResult = ReturnType<typeof useGetSingleCompanyQuery>;
export type GetSingleCompanyLazyQueryHookResult = ReturnType<typeof useGetSingleCompanyLazyQuery>;
export type GetSingleCompanyQueryResult = ApolloReactCommon.QueryResult<GetSingleCompanyQuery, GetSingleCompanyQueryVariables>;
export const GetSingleJobDocument = gql`
    query getSingleJob($jobId: ID!) {
  getJobDetails(jobId: $jobId) {
    _id
    title
    hideSalary
    countries {
      _id
      name
      code
    }
    experience
    salaryPackage
    description
    requiredSkills {
      _id
      title
    }
    company {
      _id
      name
      logoUrl
      users {
        _id
        email
      }
    }
    recruiters {
      _id
      email
      roles
    }
    leadRecruiter {
      _id
      email
    }
    fields {
      _id
      title
    }
    contractType {
      _id
      name
    }
    createdAt
    targetCompanies {
      _id
      name
    }
    currency {
      _id
      name
      symbol
    }
    status
    isAnonymous
    AnonymousCompanyTitle
    AnonymousCompanyDescription
    banner
    mobileBanner
    candidates {
      _id
    }
    language
    yearlyCompensation {
      currency
      total
      base
      onTargetEarnings
      shortTermIncentives
      longTermIncentives
      other
      comments
    }
    assessment {
      experience {
        title
        description
      }
      traits {
        title
        description
      }
    }
    progressReport
  }
}
    `;

/**
 * __useGetSingleJobQuery__
 *
 * To run a query within a React component, call `useGetSingleJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetSingleJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSingleJobQuery, GetSingleJobQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSingleJobQuery, GetSingleJobQueryVariables>(GetSingleJobDocument, baseOptions);
      }
export function useGetSingleJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSingleJobQuery, GetSingleJobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSingleJobQuery, GetSingleJobQueryVariables>(GetSingleJobDocument, baseOptions);
        }
export type GetSingleJobQueryHookResult = ReturnType<typeof useGetSingleJobQuery>;
export type GetSingleJobLazyQueryHookResult = ReturnType<typeof useGetSingleJobLazyQuery>;
export type GetSingleJobQueryResult = ApolloReactCommon.QueryResult<GetSingleJobQuery, GetSingleJobQueryVariables>;
export const GetSingleRecruiterDocument = gql`
    query getSingleRecruiter {
  getSingleRecruiter {
    _id
    fullName
    profilePic
    profileVideo
    title
    createdAt
    company
    location {
      city
      country
    }
    facebook
    twitter
    github
    linkedIn
    coverPhoto
    user {
      email
    }
    description
    educations {
      _id
      schoolName
      degree
      startYear
      location {
        city
        country
      }
      current
      endYear
      logo
    }
    experiences {
      _id
      position
      company
      startYear
      endYear
      current
      sector
      jobField
      location {
        city
        country
      }
      logo
    }
  }
}
    `;

/**
 * __useGetSingleRecruiterQuery__
 *
 * To run a query within a React component, call `useGetSingleRecruiterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleRecruiterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleRecruiterQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSingleRecruiterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSingleRecruiterQuery, GetSingleRecruiterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSingleRecruiterQuery, GetSingleRecruiterQueryVariables>(GetSingleRecruiterDocument, baseOptions);
      }
export function useGetSingleRecruiterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSingleRecruiterQuery, GetSingleRecruiterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSingleRecruiterQuery, GetSingleRecruiterQueryVariables>(GetSingleRecruiterDocument, baseOptions);
        }
export type GetSingleRecruiterQueryHookResult = ReturnType<typeof useGetSingleRecruiterQuery>;
export type GetSingleRecruiterLazyQueryHookResult = ReturnType<typeof useGetSingleRecruiterLazyQuery>;
export type GetSingleRecruiterQueryResult = ApolloReactCommon.QueryResult<GetSingleRecruiterQuery, GetSingleRecruiterQueryVariables>;
export const SkillsDocument = gql`
    query Skills($status: String) {
  skills(status: $status) {
    _id
    title
    isArchived
    isDeleted
    createdBy
  }
}
    `;

/**
 * __useSkillsQuery__
 *
 * To run a query within a React component, call `useSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillsQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSkillsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SkillsQuery, SkillsQueryVariables>) {
        return ApolloReactHooks.useQuery<SkillsQuery, SkillsQueryVariables>(SkillsDocument, baseOptions);
      }
export function useSkillsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SkillsQuery, SkillsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SkillsQuery, SkillsQueryVariables>(SkillsDocument, baseOptions);
        }
export type SkillsQueryHookResult = ReturnType<typeof useSkillsQuery>;
export type SkillsLazyQueryHookResult = ReturnType<typeof useSkillsLazyQuery>;
export type SkillsQueryResult = ApolloReactCommon.QueryResult<SkillsQuery, SkillsQueryVariables>;
export const UpdateJobIsFavouriteDocument = gql`
    mutation updateJobIsFavourite($jobId: String, $isFavourite: Boolean!) {
  updateJobIsFavourite(jobId: $jobId, action: {isFavourite: $isFavourite}) {
    _id
    title
    hideSalary
    countries {
      _id
      name
      code
    }
    experience
    salaryPackage
    description
    requiredSkills {
      _id
      title
    }
    company {
      _id
      name
      logoUrl
      users {
        _id
        email
      }
    }
    recruiters {
      _id
      email
      roles
    }
    leadRecruiter {
      _id
      email
    }
    fields {
      _id
      title
    }
    contractType {
      _id
      name
    }
    createdAt
    targetCompanies {
      _id
      name
    }
    currency {
      _id
      name
      symbol
    }
    status
    favouriteBy
  }
}
    `;
export type UpdateJobIsFavouriteMutationFn = ApolloReactCommon.MutationFunction<UpdateJobIsFavouriteMutation, UpdateJobIsFavouriteMutationVariables>;

/**
 * __useUpdateJobIsFavouriteMutation__
 *
 * To run a mutation, you first call `useUpdateJobIsFavouriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobIsFavouriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobIsFavouriteMutation, { data, loading, error }] = useUpdateJobIsFavouriteMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      isFavourite: // value for 'isFavourite'
 *   },
 * });
 */
export function useUpdateJobIsFavouriteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateJobIsFavouriteMutation, UpdateJobIsFavouriteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateJobIsFavouriteMutation, UpdateJobIsFavouriteMutationVariables>(UpdateJobIsFavouriteDocument, baseOptions);
      }
export type UpdateJobIsFavouriteMutationHookResult = ReturnType<typeof useUpdateJobIsFavouriteMutation>;
export type UpdateJobIsFavouriteMutationResult = ApolloReactCommon.MutationResult<UpdateJobIsFavouriteMutation>;
export type UpdateJobIsFavouriteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateJobIsFavouriteMutation, UpdateJobIsFavouriteMutationVariables>;
export const UpdateJobStatusDocument = gql`
    mutation updateJobStatus($jobId: String, $status: String!) {
  updateJobStatus(jobId: $jobId, action: {status: $status}) {
    _id
    title
    hideSalary
    countries {
      _id
      name
      code
    }
    experience
    salaryPackage
    description
    requiredSkills {
      _id
      title
    }
    company {
      _id
      name
      logoUrl
      users {
        _id
        email
      }
    }
    recruiters {
      _id
      email
      roles
    }
    leadRecruiter {
      _id
      email
    }
    fields {
      _id
      title
    }
    contractType {
      _id
      name
    }
    createdAt
    targetCompanies {
      _id
      name
    }
    currency {
      _id
      name
      symbol
    }
    status
  }
}
    `;
export type UpdateJobStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateJobStatusMutation, UpdateJobStatusMutationVariables>;

/**
 * __useUpdateJobStatusMutation__
 *
 * To run a mutation, you first call `useUpdateJobStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobStatusMutation, { data, loading, error }] = useUpdateJobStatusMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateJobStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateJobStatusMutation, UpdateJobStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateJobStatusMutation, UpdateJobStatusMutationVariables>(UpdateJobStatusDocument, baseOptions);
      }
export type UpdateJobStatusMutationHookResult = ReturnType<typeof useUpdateJobStatusMutation>;
export type UpdateJobStatusMutationResult = ApolloReactCommon.MutationResult<UpdateJobStatusMutation>;
export type UpdateJobStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateJobStatusMutation, UpdateJobStatusMutationVariables>;
export const UpdateRecruiterDocument = gql`
    mutation updateRecruiter($recruiterId: String!, $recruiter: UpdateRecruiter!) {
  updateRecruiter(recruiterId: $recruiterId, recruiter: $recruiter) {
    _id
    fullName
    profilePic
    profileVideo
    roles
    email
    title
    verified
    token
    company
    facebook
    twitter
    user {
      email
    }
    linkedIn
    github
    location {
      country
      city
    }
    description
  }
}
    `;
export type UpdateRecruiterMutationFn = ApolloReactCommon.MutationFunction<UpdateRecruiterMutation, UpdateRecruiterMutationVariables>;

/**
 * __useUpdateRecruiterMutation__
 *
 * To run a mutation, you first call `useUpdateRecruiterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecruiterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecruiterMutation, { data, loading, error }] = useUpdateRecruiterMutation({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *      recruiter: // value for 'recruiter'
 *   },
 * });
 */
export function useUpdateRecruiterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRecruiterMutation, UpdateRecruiterMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRecruiterMutation, UpdateRecruiterMutationVariables>(UpdateRecruiterDocument, baseOptions);
      }
export type UpdateRecruiterMutationHookResult = ReturnType<typeof useUpdateRecruiterMutation>;
export type UpdateRecruiterMutationResult = ApolloReactCommon.MutationResult<UpdateRecruiterMutation>;
export type UpdateRecruiterMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRecruiterMutation, UpdateRecruiterMutationVariables>;
export const UserAllJobsDocument = gql`
    query userAllJobs($userId: String, $searchText: String, $searchField: String, $page: Int, $limit: Int, $sortField: String, $sortOrder: String) {
  getUserJobs(
    userId: $userId
    searchText: $searchText
    searchField: $searchField
    page: $page
    limit: $limit
    sortField: $sortField
    sortOrder: $sortOrder
  ) {
    _id
    title
    hideSalary
    favouriteBy
    totalJobs
    countries {
      _id
      name
      code
    }
    salaryPackage
    experience
    requiredSkills {
      _id
      title
    }
    company {
      _id
      name
      logoUrl
      users {
        _id
        email
      }
    }
    isDraft
    currency {
      _id
      name
      symbol
    }
    leadRecruiter {
      _id
      email
    }
    fields {
      _id
      title
    }
    contractType {
      _id
      name
    }
    createdAt
    owner {
      _id
      userId
      fullName
      title
    }
    targetCompanies {
      _id
      name
    }
    recruiters {
      email
      roles
      fullName
      profilePic
    }
    recruitersDetail {
      fullName
      title
      profilePic
    }
    status
    candidates {
      _id
      recruiterAction
    }
    banner
    mobileBanner
  }
}
    `;

/**
 * __useUserAllJobsQuery__
 *
 * To run a query within a React component, call `useUserAllJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAllJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAllJobsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      searchText: // value for 'searchText'
 *      searchField: // value for 'searchField'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useUserAllJobsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserAllJobsQuery, UserAllJobsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserAllJobsQuery, UserAllJobsQueryVariables>(UserAllJobsDocument, baseOptions);
      }
export function useUserAllJobsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserAllJobsQuery, UserAllJobsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserAllJobsQuery, UserAllJobsQueryVariables>(UserAllJobsDocument, baseOptions);
        }
export type UserAllJobsQueryHookResult = ReturnType<typeof useUserAllJobsQuery>;
export type UserAllJobsLazyQueryHookResult = ReturnType<typeof useUserAllJobsLazyQuery>;
export type UserAllJobsQueryResult = ApolloReactCommon.QueryResult<UserAllJobsQuery, UserAllJobsQueryVariables>;
export const CompaniesDocument = gql`
    query Companies {
  userCompanies {
    _id
    name
    description
    logoUrl
    banner
    website
    sector {
      title
    }
    companySize
    users {
      _id
      email
      roles
    }
    jobs {
      _id
      title
    }
    totalJobs
    facebook
    linkedIn
    twitter
    isDeleted
    createdBy {
      _id
      email
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        return ApolloReactHooks.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, baseOptions);
      }
export function useCompaniesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, baseOptions);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = ApolloReactCommon.QueryResult<CompaniesQuery, CompaniesQueryVariables>;